import { calculateNeighbors } from "./calculateNeighbors.js"

export function findAllNeighbors(_touchedObject, _startingKey, _type, _maxSize){
    try{
      
      
      // create a copy of the touched object
      const tempTouchedObject = Object.assign({}, _touchedObject)
      
      var neighbors = [_startingKey]

      var neighborsToCheck = calculateNeighbors(_startingKey, _type)

      var checkedNeighbors = {
        [_startingKey]: true
      }

      while (neighborsToCheck.length > 0){
        var currentKey = neighborsToCheck.pop()
        if(_touchedObject[currentKey]){
          delete _touchedObject[currentKey]
          neighbors.push(currentKey)
          // calculate neighbors of the current key and add them to the list
          var currentKeyNeighborsToCheck = []
          // remove neighbors that are already in neighbors or in neighborsToCheck
          calculateNeighbors(currentKey, _type).forEach(elem => {
            if(checkedNeighbors[elem] == undefined){
              currentKeyNeighborsToCheck.push(elem)
            }
          });


          neighborsToCheck.push(...currentKeyNeighborsToCheck)
        }

        checkedNeighbors[currentKey] = true

        
        // if(_maxSize != null && neighbors.length >= _maxSize){

        //   break

        // }
      }

      const firstObject = Object.values(tempTouchedObject)[0]

      if(_maxSize != null && neighbors.length > _maxSize && (firstObject.isVerified == true)){
        const sectorSize = 500
        // find the starting key sector
        const startingKeyX = parseInt(_startingKey.split('-')[0])
        const startingKeyY = parseInt(_startingKey.split('-')[1])

        // divide the image up into sectors of sectorSize pixels and figure out which sector the top_left pixel is in
        const xSector = Math.floor(startingKeyX / sectorSize)
        const ySector = Math.floor(startingKeyY / sectorSize)

        // get all of the neighbors in the sector
        var sectorNeighbors = {}
        neighbors.forEach(key => {
          const x = parseInt(key.split('-')[0])
          const y = parseInt(key.split('-')[1])

          if(Math.floor(x / sectorSize) == xSector && Math.floor(y / sectorSize) == ySector){
            sectorNeighbors[key] = true
          }
        })


        // put the non-sector neighbors back in the list
        // create a set of the sector neighbors
        const nonSectorNeighbors = neighbors.filter(key => sectorNeighbors[key] == undefined)
        
        // add the non-sector neighbors back in to the _touchedObject
        nonSectorNeighbors.forEach(key => {
          _touchedObject[key] = tempTouchedObject[key]
        })


        neighbors = Object.keys(sectorNeighbors)
      
      }
      
      return neighbors

    }catch(err){
      console.error(err)
    }
  }