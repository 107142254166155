import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Chip, Drawer, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Divider, Paper, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

import AuthInterface from '../AuthInterface/AuthInterface';
import firebase from 'firebase/compat/app'


import { AccountCircle, AddCircle, AddTask, Dashboard, Business, Home, Layers, Mail, Map, Menu, People, ViewInAr, BurstMode, Payments, Widgets, Explore, TravelExplore, Policy, Public } from '@mui/icons-material';

import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/Logo_f.svg'


import { primaryTheme } from '../../theme'


import './Navbar.css'

function Navbar(props) {

    const devMode = props.devMode
    const createAlert = props.createAlert
    const organizationObj = props.organizationObj;
    const adminHRObj = props.adminHRObj;
    const currentUser = props.auth.currentUser
    const authLoaded = props.authLoaded

    const userIsOrgSuperAdmin = organizationObj?.userIsOrgSuperAdmin == true;
    const adminMode = props.adminMode;
    const mapTaskingMode = props?.adminHRObj?.mapTaskingMode == true;
    const mapReviewManagerMode = props?.adminHRObj?.mapReviewManagerMode == true;


    const [drawerOpen, setDrawerOpen] = React.useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        
    }, [props.organization])

    const toggleDrawer = (open, event) => {
        if (event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    const loggedIn = props.auth.currentUser != null
    const organizationSet = organizationObj?.organizationSet == true
    const organizationHasLots = true

    const menuItems = [
        
        {
            icon: <Map />,
            to: '/MapReview',
            text: 'Map Review',
            noLogin: false,
            section: "admin",
            showAdminView: adminMode,
            key: "mapReview"
        },
        {
            icon: <BurstMode />,
            to: '/AdminImageryReview',
            text: 'Imagery Review',
            noLogin: false,
            section: "admin",
            showAdminView: adminMode,
            key: "AdminImageryReview"
        },
        
        {
            icon: <Home />,
            to: '/',
            text: 'Home',
            noLogin: true,
            section: "general",
            showAdminView: false,
            key: "home"
        },              
        {
            icon: <Mail />,
            to: '/ContactUs',
            text: 'Contact Us',
            noLogin: true,
            section: "general",
            showAdminView: false,
            key: "contactUs"
        },
        {
            icon: <Policy />,
            to: '/TermsOfService',
            text: 'Terms Of Service',
            noLogin: true,
            section: "general",
            showAdminView: false,
            key: "termsOfService"
        },
      

    ]
    
    var consoleButton

    if(true || devMode){
        consoleButton = <AuthInterface auth={props.auth} user={props.user} createAlert={createAlert}/>
    }

    

    var organizationKeys = organizationObj?.userOrganizations != undefined ? Object.keys(organizationObj.userOrganizations):[]
    var organizationSelect = null
    if(organizationObj?.selectedOrganization != null && organizationKeys.length > 0 && loggedIn && false){
        organizationSelect = <FormControl size="small" className="navbarOrganizationPicker" >
                                <InputLabel 
                                    id="demo-simple-select-label"
                                    color="white"
                                    size="small">Organization</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={organizationObj?.selectedOrganization}
                                    label="Organization"                            
                                    onChange={(event) => {
                                        organizationObj?.setSelectedOrganization(event.target.value)
                                    }}
                                    color="secondary"
                                    style={{ color: 'white', }}>
                                    {
                                        organizationKeys.map((key) => {
                                            const displayName = organizationObj.userOrganizations[key].displayName
                                            return <MenuItem value={key} key={key}>{displayName}</MenuItem>
                                        })
                                    }                      
                                </Select>
                                                      
                            </FormControl>                                   
    }else if(loggedIn && false){
        // circular loading progress
        organizationSelect = <CircularProgress color='secondary' size={20} style={{marginLeft: '20px'}}/>
    }

    return (
        <>
            <AppBar position="static" className="appBar">
                <Toolbar>
                    <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    style={{ mr: 2 }}
                    onClick={(event) => {toggleDrawer(true, event)}}
                    >
                        <Menu />
                    </IconButton>
                    <a onClick={() => navigate("/")} className='hidden-mobile'>
                        <img src={logo} alt="Logo" className="appBarHeaderLogo"/>                   
                    </a>
                    {
                        organizationSelect
                    }  
                    <div style={{flex: 1}}/>                    
                    { consoleButton }                    
                    
                    
                </Toolbar>
            </AppBar>
            <Drawer
                anchor={'left'}
                open={drawerOpen}
                onClose={(event) => {toggleDrawer(false, event)}}
                >
                 <Box
                    style={{ width: 270 }}
                    role="presentation"
                    onClick={() => toggleDrawer(false, {})}
                    onKeyDown={() => toggleDrawer(false, {})}
                    >
                <Paper className='drawerHeader' sx={{backgroundColor: "primary.main"}} elevation={2}>
                    
                    <img src={logo} onClick={() => navigate("/")} alt="Logo" className="appBarHeaderLogo"/>                        
                    
                </Paper>
                <List>     
                    {
                        adminMode ? <Divider style={{marginBottom: '5px'}}><Chip label="Admin" /></Divider>:null
                    }    
                    {
                        adminMode ? menuItems.filter(e => !e.noLogin && e.showAdminView && e.section == "admin").map(item => {                            
                            return (
                                <Link to={item.to} key={item.key}>
                                    <ListItem key={""} disablePadding>                        
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>                            
        
                            )
                        
                        }):null
                    }
                    
                    <Divider style={{marginBottom: '5px', marginTop: '5px'}}><Chip label="General" /></Divider>                    
                    {
                        menuItems.filter(e => e.noLogin && e.section == "general").map(item => {                        
                            return (
                                <Link to={item.to} key={item.key}>
                                    <ListItem key={""} disablePadding>                        
                                        <ListItemButton>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>                            
        
                            )                        
                        })
                    } 

                </List>                
                </Box>
            </Drawer>
        </>
    )

}


export default Navbar;