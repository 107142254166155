import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import firebase from 'firebase/compat/app'
import { getStorage, ref, listAll, getDownloadURL, getMetadata, uploadBytes } from "firebase/storage";
import { GoogleMap, StreetViewPanorama, useLoadScript, Marker, Polygon, GroundOverlay, Polyline, Rectangle } from '@react-google-maps/api';
import { useTheme, styled } from '@mui/material/styles';
import { Badge, Button, Divider, IconButton, MenuItem, Paper, Select, Slider, Stack } from '@mui/material';
import { AccountTree, Add, BrightnessHigh, Image as ImageIcon, BrightnessLow, Cloud, DataObject, Event, FormatColorFill, FormatShapes, LibraryAddCheck, Mail, ModeStandby, PhotoLibrary, Save, SelectAll, Upload, RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined, FilterDrama, } from '@mui/icons-material';
import * as turf from '@turf/turf';

import { calculateDateMarkStyles } from '../../functions/calculateDateMarkStyles';
import { calculateDateSliderMarks } from '../../functions/calculateDateSliderMarks';
import { dateIdToAnalyticsRequestId } from '../../functions/dateIdToAnalyticsRequestId'
import { dateIdToAnalyticsRequestIdShorthand } from '../../functions/dateIdToAnalyticsRequestIdShorthand'

import DataDisplay from '../DataDisplay/DataDisplay';

import { boundsToPolygon } from '../../functions/boundsToPolygon';
import { createRandomKey } from '../../functions/createRandomKey'
import { dateToLocaleUTCDateString } from '../../functions/dateToLocaleUTCDateString'
import { formatDateAsId } from '../../functions/formatDateAsId';

import { findAllNeighbors } from '../../functions/calculateNeighbors/findAllNeighbors'
import { calculateNeighbors } from '../../functions/calculateNeighbors/calculateNeighbors';


import googleMapsStyle from '../../assets/googleMapsStyle.json'
import settings from '../../settings.json';

import './AdminImageryReview.css'


import { createAdminSquareFromId } from '../../functions/createAdminSquareFromId'
import { polygon } from '@turf/turf';
import zIndex from '@mui/material/styles/zIndex';
import DataDisplayEditableSelect from '../DataDisplayEditableSelect/DataDisplayEditableSelect';

function AdminImageryReview(props) {

    const firestore = firebase.firestore();

    const actionTimeout = 5000

    const maxCloudMaskSize = 5000

    const adminMode = props.adminMode
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentLocationRef  = useRef({lat: 28.8029, lng: -81.2695});  
    const adminImageryReviewObj = props?.adminImageryReviewObj
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const {isLoaded} = useLoadScript({googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"})


    const navigate = useNavigate()
    const storage = getStorage();
    const theme = useTheme();

    const currentSquareRef = useRef(null)
    const currentSquareDateIdRef = useRef(null)
    const [currentSquareDateId, setCurrentSquareDateId] = useState(null)

    const currentSquareDateIdSelectedImageRef = useRef(null)
    const [currentSquareDateIdImageSelectedOption, setCurrentSquareDateIdImageSelectedOption] = useState(null)
    const currentSquareDateIdImageSelectOptionsRef = useRef([])
    const [currentSquareDateIdImageSelectOptions, setCurrentSquareDateIdImageSelectOptions] = useState([])
    const mapRef = useRef(null);
    const squaresRef = useRef({})
    const [viewportReRendererId, setViewportReRendererId] = useState(0)
    const lastViewportRenderRef = useRef(0)
    const lastViewportRenderTimeoutRef = useRef(null)
    const squareDataObjRef = useRef({})
    const squareDataYearlyObjRef = useRef({}) // used to store the square data for the yearly view
    const currentSquareImageryObjRef = useRef(null)

    const squareImageryGeneralBasemapRef = useRef({})
    const [currentSquareGeneralSquareBasemapUrl, setCurrentSquareGeneralSquareBasemapUrl] = useState(null)

    const squareJSONCloudMaskRef = useRef({})
    const squareJSONMetaDataRef = useRef({})
    const squareJSONCloudMaskMLRef = useRef({})
    const squareCloudMaskTouchedSquaresRef = useRef({})
    const squareCloudMaskPolygonsRef = useRef({})
    const [currentSquareDateCloudMaskPolygons, setCurrentSquareDateCloudMaskPolygons] = useState([])

    const dateSliderValueRef = useRef(null)
    const dateSliderMarksRef = useRef(null)
    const currentSquareVisiblePngsRef = useRef(null)
    const currentSquareObjectRef = useRef(null)
    const dateSliderMarksLoadStateRef = useRef({})
    const taskReviewIndexOfKeyRef = useRef(null)
    const imageryReviewMaskObjectRef = useRef({})
    const selectedPolygonKeyRef = useRef(null)
    const loadedMasksFromStorage = useRef({})

    const [mapZoom, setMapZoom] = useState(12)
    const [location, setLocation] = useState({lat: currentLocationRef.current.lat, lng: currentLocationRef.current.lng,})
    const [mapTypeId, setMapTypeId] = useState('roadmap');
    const [visibleSquaresArray, setVisibleSquaresArray] = useState([])
    const [currentSquare, setCurrentSquare] = useState(null)
    const [currentSquareImageryObj, setCurrentSquareImageryObj] = useState(null)
    const [currentSquareVisiblePngs, setCurrentSquareVisiblePngs] = useState(null)
    const [currentSquareCloudMaskPolygons, setCurrentSquareCloudMaskPolygons] = useState([])
    const basemapImageryComparisonObjRef = useRef({})
    const [currentSquareBasemapImageryComparisonUrl, setCurrentSquareBasemapImageryComparisonUrl] = useState(null)

    const [refreshKey, setRefreshKey] = useState(0)
    const [currentSquareObject, setCurrentSquareObject] = useState(null)
    const [currentSquarePolygonToolMask, setCurrentSquarePolygonToolMask] = useState(null)
    const [selectedPolygonKey, setSelectedPolygonKey] = useState(null)

    const defualtImageryBrightness = 10
    const defualtImagerySaturation = 1
    const [imageryBrightness, setImageryBrightness] = useState(defualtImageryBrightness)

    const defaultcloudMaskThreshold = 0
    const cloudMaskThresholdRef = useRef(defaultcloudMaskThreshold)
    const [cloudMaskThreshold, setCloudMaskThreshold] = useState(defaultcloudMaskThreshold)

    const [dateSliderValue, setDateSliderValue] = useState(null)
    const [dateSliderMarks, setDateSliderMarks] = useState(null)
    const [dateSliderMinDate, setDateSliderMinDate] = useState(null)
    const [dateSliderMaxDate, setDateSliderMaxDate] = useState(null)
    const [dateSliderMarksLoadStateStyle, setDateSliderMarksLoadStateStyle] = useState({})

    const currentSquareSelectedToolTypeRef = useRef("paintBucket")
    const [currentSquareSelectedToolType, setCurrentSquareSelectedToolType] = useState(currentSquareSelectedToolTypeRef.current)
    const currentSquarePolygonToolMaskRef = useRef({})
    
    const currentSquareShowGeneralBasemapRef = useRef(false)
    const currentSquareShowPolygonsRef = useRef(true)
    const [currentSquareShowGeneralBasemap, setCurrentSquareShowGeneralBasemap] = useState(currentSquareShowGeneralBasemapRef.current)
    const [currentSquareShowPolygons, setCurrentSquareShowPolygons] = useState(currentSquareShowPolygonsRef.current)
    const currentSquareShowGeneralBasemapDiffRef = useRef(true)
    const [currentSquareShowGeneralBasemapDiff, setCurrentSquareShowGeneralBasemapDiff] = useState(currentSquareShowGeneralBasemapDiffRef.current)

    const cloudMaskLocationHistoryRef = useRef([])
    const cloudMaskLocationHistoryIndexRef = useRef(-1)

    const cloudMaskChangeHistoryRef = useRef([])
    const cloudMaskChangeHistoryIndexRef = useRef(-1)

    const [debugginsPolygon, setDebugginsPolygon] = useState(null)
    useEffect(() => {
        init()

        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }

    }, [])

    function init() {
        getAllActiveSquares()
    }

    function handleKeyDown(event){

        const activeElement = document.activeElement;
    
        const isInputField = 
            activeElement.tagName === 'INPUT' || 
            activeElement.tagName === 'TEXTAREA' || 
            activeElement.isContentEditable;
    
        if(isInputField){
          return
        }
    
        try{
            if(event.ctrlKey && event.shiftKey && event.keyCode == 90){
                //ctrl + shift + z key
                moveThroughChangeHistory("forward")

            }else if(event.ctrlKey && event.keyCode == 90){
                //ctrl + z key
                moveThroughChangeHistory("back")

            }else if(event.keyCode == 219){
                //left bracket key
                moveThroughLocationHistory("back")
            }else if(event.keyCode == 221){
                //right bracket key
                moveThroughLocationHistory("forward")
            }else if(event.keyCode == 32){
                //space key
                selectNextUnverifiedCloudMask()
            }else if(event.keyCode == 87){
                //w key
                changeSelectedToolType("paintBucket")
            }else if(event.keyCode == 82){
                //r key
                changeSelectedToolType("polygonTool")
            }else if(event.keyCode == 69){
                //e key
                changeSelectedToolType("spotTool")
            }else if(event.keyCode == 83){
                //s key
                currentSquareShowPolygonsRef.current = !currentSquareShowPolygonsRef.current
                setCurrentSquareShowPolygons(currentSquareShowPolygonsRef.current)
            }else if(event.keyCode == 68){
                //d key
                currentSquareShowGeneralBasemapDiffRef.current = !currentSquareShowGeneralBasemapDiffRef.current
                setCurrentSquareShowGeneralBasemapDiff(currentSquareShowGeneralBasemapDiffRef.current)
                
            }else if(event.keyCode == 70){
                //f key
                
            }else{
                console.log(event.keyCode)
            }

        }catch(err){

        }
    }

    function mapHandleOnLoad(map) {
        mapRef.current = map;    
    
    };

    const handleCenterChanged = (_map) => {
        if (_map) {
          currentLocationRef.current = {lat:_map.center.lat(), lng: _map.center.lng()}
        }
    };

    function mapHandleBoundsChanged(){
        try{
          if (mapRef.current) {
            // requestViewportRender()
          }
        }catch(err){
          console.error(err)
        }
      }


    // ################################################################
    // ################################################################
    // ################################################################
    // ################################################################
    function groupToPolygon(cells, _latLngs) {
        const edges = new Map();
      
        // Helper: add an edge (or remove it if already added in reverse).
        function addEdge(start, end) {
          const key = start.join(',') + '-' + end.join(',');
          const revKey = end.join(',') + '-' + start.join(',');
          if (edges.has(revKey)) {
            // Shared (internal) edge—remove it.
            edges.delete(revKey);
          } else {
            edges.set(key, { start, end });
          }
        }
      
        // Process each cell (cells are in "x-y" format).
        cells.forEach(cell => {
          const [xStr, yStr] = cell.split("-");
          const x = parseInt(xStr), y = parseInt(yStr);
          const s = settings.ANALYTICS_PIXELS_PER_SQUARE;
          const topLeft     = [x, y];
          const topRight    = [x + s, y];
          const bottomRight = [x + s, y + s];
          const bottomLeft  = [x, y + s];
      
          addEdge(topLeft, topRight);
          addEdge(topRight, bottomRight);
          addEdge(bottomRight, bottomLeft);
          addEdge(bottomLeft, topLeft);
        });
      
        // Convert remaining edges to an array.
        let segments = Array.from(edges.values());
        if (segments.length === 0) {
          return null;
        }
      
        // Helper to compare two points.
        function pointsEqual(a, b) {
          return a[0] === b[0] && a[1] === b[1];
        }
      
        // Helper: compute the angle between two vectors.
        function angleBetween(a, b) {
          let dot = a[0]*b[0] + a[1]*b[1];
          let magA = Math.sqrt(a[0]*a[0] + a[1]*a[1]);
          let magB = Math.sqrt(b[0]*b[0] + b[1]*b[1]);
          let cosTheta = dot / (magA * magB);
          // Clamp due to floating point precision.
          cosTheta = Math.max(-1, Math.min(1, cosTheta));
          return Math.acos(cosTheta);
        }
      
        // Stitch segments into continuous loops.
        const loops = [];
        while (segments.length > 0) {
          let boundary = [];
          // Start with one segment.
          let current = segments.shift();
          boundary.push(current.start, current.end);
      
          while (true) {
            let currentPoint = boundary[boundary.length - 1];
            // Find all segments that connect to the current point.
            let candidates = segments.filter(seg => pointsEqual(currentPoint, seg.start) || pointsEqual(currentPoint, seg.end));
            if (candidates.length === 0) break;
      
            let chosenSegment;
            if (candidates.length === 1) {
              chosenSegment = candidates[0];
            } else {
              // If more than one candidate, use the turning angle.
              let incoming;
              if (boundary.length > 1) {
                const prev = boundary[boundary.length - 2];
                incoming = [currentPoint[0] - prev[0], currentPoint[1] - prev[1]];
              } else {
                // Default vector if none exists.
                incoming = [1, 0];
              }
              let bestAngle = 0;
              for (let seg of candidates) {
                // Determine the candidate's "other" point.
                const other = pointsEqual(currentPoint, seg.start) ? seg.end : seg.start;
                const candidateVector = [other[0] - currentPoint[0], other[1] - currentPoint[1]];
                const angle = angleBetween(incoming, candidateVector);
                if (angle > bestAngle) {
                  bestAngle = angle;
                  chosenSegment = seg;
                }
              }
            }
      
            // Append the chosen segment's opposite endpoint.
            const nextPoint = pointsEqual(currentPoint, chosenSegment.start) ? chosenSegment.end : chosenSegment.start;
            boundary.push(nextPoint);
            segments.splice(segments.indexOf(chosenSegment), 1);
      
            // If the loop is closed, exit.
            if (pointsEqual(boundary[0], nextPoint)) break;
          }
      
          // Ensure the loop is explicitly closed.
          if (!pointsEqual(boundary[0], boundary[boundary.length - 1])) {
            boundary.push(boundary[0]);
          }
          loops.push(boundary);
        }
      
        // Compute area using the shoelace formula.
        function polygonArea(points) {
          let area = 0;
          for (let i = 0; i < points.length - 1; i++) {
            area += points[i][0] * points[i+1][1] - points[i+1][0] * points[i][1];
          }
          return Math.abs(area / 2);
        }
      
        // Determine the outer loop (largest area) and treat the rest as holes.
        let outerLoop = loops[0];
        let outerArea = polygonArea(outerLoop);
        const holes = [];
      
        loops.forEach(loop => {
          const area = polygonArea(loop);
          if (area > outerArea) {
            holes.push(outerLoop);
            outerLoop = loop;
            outerArea = area;
          } else if (loop !== outerLoop) {
            holes.push(loop);
          }
        });
      
        // Convert pixel coordinates to lat/lng.
        function convertLoop(loop) {
          return loop.map(point => {
            const x = point[0], y = point[1];
            const lat = _latLngs['lats'][x];
            const lng = _latLngs['lngs'][y];
            return [lng, lat]; // GeoJSON expects [lng, lat]
          });
        }
      
        const outerLatLng = convertLoop(outerLoop);
        const holesLatLng = holes.map(convertLoop);
      
        // Return the Turf.js polygon with holes.
        return turf.polygon([outerLatLng, ...holesLatLng]);
      }
      


    // ################################################################
    // ################################################################
    // ################################################################
    // ################################################################
        
        
    
      

    

    function calculateCloudMaskPolygons(_cloudsObject, _latLngs){

        var tempPolygons = []
        const groupingPossibleCloudsObject = {}
        const groupingFalsePositiveObject = {}
        const groupingPossibleFalsePositiveObject = {}
        const gropuingVerifiedCloudsObject = {}
        const groupingVerifiedNotCloudsObject = {}


        Object.entries(_cloudsObject).forEach(([key, value]) => {

            if(value.isVerified){
                if(value.isCloud){                    
                    gropuingVerifiedCloudsObject[key] = {
                        isCloud: true,
                        isVerified: true,
                        falsePositive: false,
                    }            
                }else{
                    if(value.falsePositive){
                        groupingFalsePositiveObject[key] = {
                            isCloud: false,
                            isVerified: true,
                            falsePositive: true,
                        }
                    }else{
                        groupingVerifiedNotCloudsObject[key] = {
                            isCloud: false,
                            isVerified: true,
                            falsePositive: false,
                        }
                    }
                }
            }else{
                if(value.isCloud){
                    if(value.falsePositive || value.isMLFalsePositive){
                        groupingPossibleFalsePositiveObject[key] = {
                            isCloud: true,
                            isVerified: false,
                            falsePositive: true,
                            isMLFalsePositive: value.isMLFalsePositive,
                        }
                    }else{
                        groupingPossibleCloudsObject[key] = {
                            isCloud: true,
                            isVerified: false,
                            falsePositive: false,
                            isMLFalsePositive: value.isMLFalsePositive,
                        }
                    }

                }else if(!value.isCloud && value.isMLFalseNegative){                    
                    groupingPossibleFalsePositiveObject[key] = {
                        isCloud: true,
                        isVerified: false,
                        falsePositive: false,
                    }
                }
            }

        });

        const elementsArray = [groupingPossibleCloudsObject, groupingPossibleFalsePositiveObject, groupingFalsePositiveObject, gropuingVerifiedCloudsObject, groupingVerifiedNotCloudsObject]

        elementsArray.forEach((cloudsObject, index) => {
            while (Object.keys(cloudsObject).length > 0){

                //get a random key from the cloudsObject
                const keys = Object.keys(cloudsObject)
                const randomIndex = Math.floor(Math.random() * keys.length);
                const firstKey = keys[randomIndex]
                const firstElement = cloudsObject[firstKey]
                //remove the first element from the category
                delete cloudsObject[firstKey]
                //create a touched object
                
                //get the neighbours of the first element
                const neighbours = findAllNeighbors(cloudsObject, firstKey, "diamond", maxCloudMaskSize)

                const randomKey = createRandomKey()
                const polygon = groupToPolygon(neighbours, _latLngs)

                // const polygon = calculatePolygonFromKeys(neighbours, _latLngs)
                const paths = []
                polygon.geometry.coordinates.forEach((coordinateArray) => {
                    const path = []
                    coordinateArray.forEach((coordinate) => {
                        const lat = coordinate[1]
                        const lng = coordinate[0]
                        if (!isNaN(lat) && !isNaN(lng)){
                            path.push({lat: lat, lng: lng})
                        }
                    })
                    paths.push(path)
                })

                const isVerified = firstElement.isVerified
                const isCloud = firstElement.isCloud
                const falsePositive = firstElement.falsePositive

                const keysObject = {}
                const keysArray = [firstKey, ...neighbours]
                keysArray.forEach((key) => {
                    keysObject[key] = true
                })

                // find the polygon bounds 
                const polygonBounds = turf.bbox(polygon)
                const polygonArea = turf.area(polygon)

                const turfFormattedPolygonObj = {
                    type: "Polygon",
                    coordinates: paths.map(ring =>
                        ring.map(point => [point.lng, point.lat]) // Convert { lat, lng } to [lng, lat]
                      )
                }
                const turfPolygon = turf.polygon(turfFormattedPolygonObj.coordinates)
                

                tempPolygons.push({
                    polygonKey: randomKey,
                    keys: keysObject,
                    polygon: paths,
                    turfPolygon: turfPolygon,
                    bounds: {
                        north: polygonBounds[3],
                        south: polygonBounds[1],
                        east: polygonBounds[2],
                        west: polygonBounds[0],
                    },
                    polygonArea: polygonArea,
                    isVerified: isVerified,
                    isCloud: isCloud,
                    falsePositive: falsePositive
                })
            }

        })

        return tempPolygons
        
        
    }

    async function fetchCloudMaskJSONDataFromURL(_url){

        return new Promise(async (resolve, reject) => {
            try{

                // fetch the json file
                const response = await fetch(_url)
                response.json().then((json) => {

                    resolve({
                        mask: json.mask,
                        ml_cloudFalsePositive: json.ml_cloudFalsePositive,
                        ml_notCloudFalseNegative: json.ml_notCloudFalseNegative,
                        metadata: json.metadata
                    })
                })
            }catch(err){
                console.error(err)
                createAlert('error', 'Error calculating cloud mask polygons')
                resolve({
                    mask: [],
                    ml_cloudFalsePositive: null,
                    ml_notCloudFalseNegative: null,
                    metadata: {}
                })
            }
        })
    }

    function reRenderPolygons(_forceRender, _checkIntersections){
        try{
            // TODO: Implement caching mechanism for faster re-rendering
            var tempCloudsObject = {};

            const squareLatLngs = squareJSONMetaDataRef.current[currentSquareRef.current][currentSquareDateIdRef.current]?.[currentSquareDateIdSelectedImageRef.current]?.squareLatLngs
            const touchedCloudMaskSquares = squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] ?? []
            
            if(![squareLatLngs, touchedCloudMaskSquares].includes(undefined)){
                
                if(touchedCloudMaskSquares.length > 0 || _forceRender){ 
                    // calculate the polygons that makeup the latest touched squares  
                    var numFalsePositives = 0
                    var numfalseNegatives = 0
                    touchedCloudMaskSquares.forEach((element) => {
                        

                        
                        const maskKey = element.maskKey
                        const isVerified = element.isVerified
                        const isCloud = element.isCloud
                        const falsePositive = element.falsePositive
                        const isMLFalsePositive = element.isMLFalsePositive
                        const isMLFalseNegative = element.isMLFalseNegative 

                        if(isMLFalsePositive){
                            numFalsePositives++
                        }
                        if(isMLFalseNegative){
                            numfalseNegatives++
                        }

                        tempCloudsObject[maskKey] = {
                            isCloud: isCloud,
                            isVerified: isVerified,
                            falsePositive: falsePositive,
                            isMLFalsePositive: isMLFalsePositive,
                            isMLFalseNegative: isMLFalseNegative


                        }

                    })
                    
                    
                    const tempPolygons = calculateCloudMaskPolygons(tempCloudsObject, squareLatLngs)                
                    squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = []

                    if(squareCloudMaskPolygonsRef.current[currentSquareRef.current] == null){
                        squareCloudMaskPolygonsRef.current[currentSquareRef.current] = {}
                    }
                    if(squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current] == null){
                        squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current] = {}
                    }
                    if(squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] == null){
                        squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = []
                    }
                    

                    // for each of the tempPolygons check if the polygon intersects with one of the existing polygons in the squareCloudMaskPolygons and if they are both verified or not
                    const allPolygons = []
                    try{
                        squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].forEach((element) => {
                            allPolygons.push(element)
                        })
                    }catch(err){
                        // expected failure
                        // console.error(err)
                    }

                    const intersectingPolygons = {}
                    tempPolygons.forEach((tempPolygon) => {                        
                        allPolygons.forEach((e) => {                            
                            if(turf.booleanIntersects(tempPolygon.turfPolygon, e.turfPolygon)){
                                intersectingPolygons[e.polygonKey] = e
                            }
                        })                                            
                    })
                    
                    const affectedKeys = []


                    const tempAllPolygons = [...allPolygons, ...tempPolygons]

                    try{
                        // // check if any of the possible false positive polygons intersect with any verified cloud extremely large polygons > 5000 squares
                        tempAllPolygons
                            .filter((element) => { return element.isCloud && element.falsePositive == true && element.isVerified == false })
                            .forEach((tempPolygon) => {
                                // check the temp polygons 
                                tempPolygons.filter((largePolygon) => {
                                    return Object.keys(largePolygon.keys).length > maxCloudMaskSize && largePolygon.polygonKey != tempPolygon.polygonKey && largePolygon.isCloud == true
                                }).forEach((largePolygon) => {
                                    // if the possible false positive temp polygon intersects with the large polygon then go ahead and set it as a cloud
                                    if(turf.booleanIntersects(tempPolygon.turfPolygon, largePolygon.turfPolygon)){
                                        // update the temp polygon to be a cloud and not a false positive
                                        // find the index in the tempPolygons array
                                        const index = tempPolygons.findIndex((element) => {
                                            return element.polygonKey == tempPolygon.polygonKey
                                        })
                                        tempPolygons[index].falsePositive = false
                                        tempPolygons[index].isCloud = true


                                        intersectingPolygons[largePolygon.polygonKey] = largePolygon


                                    }
                                })


                                

                                // check the existing polygons
                            })
                    }catch(err){
                        console.error(err)
                    }

                    if(Object.keys(intersectingPolygons).length > 0 && _checkIntersections){
                        

                        
                        const affectedPolygonsArray = [Object.values(intersectingPolygons), tempPolygons]
                        affectedPolygonsArray.forEach(element => {
                            element.forEach((polygon) => {
                                Object.keys(polygon.keys).forEach((maskKey) => {
                                    affectedKeys.push({
                                        maskKey: maskKey,
                                        isCloud: polygon.isCloud,
                                        isVerified: polygon.isVerified,
                                        falsePositive: polygon.falsePositive
                                    })
                                })
                            })
                        })

                        

                        squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = affectedKeys
                        // remove the intersecting polygons from the allPolygons array
                        const filteredPolygons = allPolygons.filter((polygon) => {
                            return !Object.keys(intersectingPolygons).includes(polygon.polygonKey)
                        })
                        // update the squareCloudMaskPolygonsRef array with the new polygons
                        squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = filteredPolygons

                        reRenderPolygons(false, false)

                    }else{
                        
                        tempPolygons.forEach((tempPolygon) => {
                            allPolygons.push(tempPolygon)
                        })

                        // filter out polygons that are too small
                        const filteredPolygons = allPolygons.filter((element) => {
                            return Object.keys(element.keys).length > 4
                        })

                        squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = filteredPolygons

                        // check if the history is not at the end index
                        if(cloudMaskChangeHistoryIndexRef.current < cloudMaskChangeHistoryRef.current.length){
                            // if it is not at the end index then remove all elements after the current index
                            cloudMaskChangeHistoryRef.current = cloudMaskChangeHistoryRef.current.slice(0, cloudMaskChangeHistoryIndexRef.current + 1)


                        }

                        cloudMaskChangeHistoryRef.current.push(filteredPolygons)
                        cloudMaskChangeHistoryIndexRef.current = cloudMaskChangeHistoryRef.current.length
                        setCurrentSquareCloudMaskPolygons(filteredPolygons)
                        setViewportReRendererId(Math.random() * 1000)
                    }

                    
                }
            }else{

                cloudMaskChangeHistoryRef.current = []
                cloudMaskChangeHistoryIndexRef.current = -1

                setCurrentSquareCloudMaskPolygons([])
                setViewportReRendererId(Math.random() * 1000)
            }
        }catch(err){
            console.error(err)
            cloudMaskChangeHistoryRef.current = []
            cloudMaskChangeHistoryIndexRef.current = -1

            setCurrentSquareCloudMaskPolygons([])
            setViewportReRendererId(Math.random() * 1000)
        }
    }

    function fetchSquareDateMaskData(_dateId){
        return new Promise(async (resolve, reject) => {
            try{
                if(squareJSONMetaDataRef.current[currentSquareRef.current] == null){
                    squareJSONMetaDataRef.current[currentSquareRef.current] = {}
                }

                if(squareJSONCloudMaskRef.current[currentSquareRef.current][_dateId] == null){


                    // foreach image id
                    squareJSONCloudMaskRef.current[currentSquareRef.current][_dateId] = {}
                    squareJSONCloudMaskMLRef.current[currentSquareRef.current][_dateId] = {}

                    if(squareJSONMetaDataRef.current[currentSquareRef.current][_dateId] == null){
                        squareJSONMetaDataRef.current[currentSquareRef.current][_dateId] = {}
                    }
                    

                    for (const imageId of Object.keys(squareDataObjRef.current[currentSquareRef.current][_dateId])) {

                        squareJSONCloudMaskRef.current[currentSquareRef.current][_dateId][imageId] = {
                            "working": [],
                            "official": []
                        }
                        squareJSONCloudMaskMLRef.current[currentSquareRef.current][_dateId][imageId] = {
                            "ml_cloudFalsePositive": null,
                            "ml_notCloudFalseNegative": null
                        }



                        const imageElement = squareDataObjRef.current[currentSquareRef.current][_dateId][imageId]
                        

                        // check if imageElement[dateId].json.rawCloudMask Default / Working / Official exists
                        const defaultExists = imageElement.json.rawCloudMask?.Default != null
                        const workingExists = imageElement.json.rawCloudMask?.Working != null
                        const officialExists = imageElement.json.rawCloudMask?.Official != null

                        var tempWorkingCloudMaskData = {
                            mask: null,
                            ml: {
                                ml_cloudFalsePositive: null,
                                ml_notCloudFalseNegative: null,
                            },
                            metadata: null,
                        }

                        var tempOfficialCloudMaskData = {
                            mask: null,
                        }



                        if(officialExists){
                            const tempCloudMaskData = await fetchCloudMaskJSONDataFromURL(imageElement.json.rawCloudMask.Official.url)
                            if(tempCloudMaskData.mask.length > 0){
                                tempWorkingCloudMaskData.mask = tempCloudMaskData.mask
                                tempOfficialCloudMaskData.mask = tempCloudMaskData.mask
                            }
                            if(tempCloudMaskData.metadata != null){
                                tempWorkingCloudMaskData.metadata = tempCloudMaskData.metadata
                            
                            }
                            
                        }else if(workingExists){
                            const tempCloudMaskData = await fetchCloudMaskJSONDataFromURL(imageElement.json.rawCloudMask.Working.url)
                            if(tempCloudMaskData.mask.length > 0){
                                tempWorkingCloudMaskData.mask = tempCloudMaskData.mask
                            }
                            if(tempCloudMaskData.metadata != null){
                                tempWorkingCloudMaskData.metadata = tempCloudMaskData.metadata
                            }                            
                        }
                        
                        
                        if(defaultExists){
                            const tempCloudMaskData = await fetchCloudMaskJSONDataFromURL(imageElement.json.rawCloudMask.Default.url)

                            if(!officialExists && !workingExists){
                                if(tempCloudMaskData.mask.length > 0){
                                    tempWorkingCloudMaskData.mask = tempCloudMaskData.mask
                                }
                                if(tempCloudMaskData.metadata != null){
                                    tempWorkingCloudMaskData.metadata = tempCloudMaskData.metadata
                                }                                
                            }

                            if(tempCloudMaskData.ml_cloudFalsePositive != null){
                                tempWorkingCloudMaskData.ml.ml_cloudFalsePositive = tempCloudMaskData.ml_cloudFalsePositive
                            }
                            if(tempCloudMaskData.ml_notCloudFalseNegative != null){
                                tempWorkingCloudMaskData.ml.ml_notCloudFalseNegative = tempCloudMaskData.ml_notCloudFalseNegative
                            }


                        } 

                        const workingMask = tempWorkingCloudMaskData.mask
                        const workingMetaData = tempWorkingCloudMaskData.metadata
                        squareJSONCloudMaskRef.current[currentSquareRef.current][_dateId][imageId]["working"] = workingMask
                        squareJSONCloudMaskRef.current[currentSquareRef.current][_dateId][imageId]["official"] = tempOfficialCloudMaskData.mask
                        squareJSONCloudMaskMLRef.current[currentSquareRef.current][_dateId][imageId]["ml_cloudFalsePositive"] = tempWorkingCloudMaskData.ml.ml_cloudFalsePositive
                        squareJSONCloudMaskMLRef.current[currentSquareRef.current][_dateId][imageId]["ml_notCloudFalseNegative"] = tempWorkingCloudMaskData.ml.ml_notCloudFalseNegative
                        squareJSONMetaDataRef.current[currentSquareRef.current][_dateId][imageId] = workingMetaData

                    }

                    resolve()
                    
                }else{
                    // imagery already loaded
                    resolve()
                }
            
            }catch(err){
                console.error(err)
                createAlert('error', 'Error fetching cloud mask data')
                resolve()
            }
        })
    }

    function processBasemapDifferences(baseImageData, newImageData) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        
        // Set canvas size
        canvas.width = baseImageData.width;
        canvas.height = baseImageData.height;
    
        // Draw images and process them
        
        const baseData = baseImageData.rgbArray;
        const newData = newImageData.rgbArray;
    
        // Create output image
        const outputData = ctx.createImageData(canvas.width, canvas.height);
        var maxDiff = 0;
        // find the mean of both images
        var meanBase = 0;
        var meanNew = 0;
        for (let i = 0; i < baseData.length; i += 4) {
            meanBase += (baseData[i] + baseData[i + 1] + baseData[i + 2]) / 3;
            meanNew += (newData[i] + newData[i + 1] + newData[i + 2]) / 3;
        }
        meanBase /= (baseData.length / 4);
        meanNew /= (newData.length / 4);
        
        // use the differences to apply a bias to the images to account for the mean difference
        const bias = meanBase - meanNew;

        for (let i = 0; i < baseData.length; i += 4) {
            // compute the max diff between each channel in both images
            const baseR = baseData[i];
            const baseG = baseData[i + 1];
            const baseB = baseData[i + 2];
            const newR = newData[i] + bias;
            const newG = newData[i + 1] + bias;
            const newB = newData[i + 2] + bias;

            // find the mean difference and determine if it's positive or negative
            // if it's positive set the diff to red if it's negative set it to blue
            const diff = ((newR - baseR) + (newG - baseG) + (newB - baseB))/3;

            if(diff > maxDiff){
                maxDiff = diff;
            }

            
            
            

            // if(Math.abs(shadowDiff) > Math.abs(cloudDiff)){
            outputData.data[i] = 255;
            outputData.data[i + 1] = 0;
            outputData.data[i + 2] = 0;
            outputData.data[i + 3] = diff > cloudMaskThresholdRef.current ? 255:0 ; // Set alpha to 255 if diff is significant, else 0

    
            
        }

        // Normalize the output data to be between 0 and 255
        for (let i = 0; i < outputData.data.length; i += 4) {
            outputData.data[i] = (outputData.data[i] / meanNew) * 255 ; // Red channel
            
            
        }
    
        ctx.putImageData(outputData, 0, 0);
    
        // Callback with the saved URL
        return canvas.toDataURL();
        
              
      }
      
    function extractImageDataFromUrl(url) {
        // use canvas to extract image data from url and format as an rgb object array
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "anonymous";

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                
                const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
                
                
                

                resolve({
                    "rgbArray": imageData,
                    "height": img.height,
                    "width": img.width
                });
            }
            img.onerror = (err) => {
                console.error("Error loading image from URL", err);
                reject(err);
            };
            img.src = url;

        });
    }

    function fetchImageData({ type, key, url }) {
                
        if(basemapImageryComparisonObjRef.current[currentSquareRef.current] == null){
            basemapImageryComparisonObjRef.current[currentSquareRef.current] = {
                "yearlyImagery": {},
                "dailyImagery": {}
            }
        }
        if(basemapImageryComparisonObjRef.current[currentSquareRef.current]["dailyImagery"][currentSquareDateIdRef.current] == null){
            basemapImageryComparisonObjRef.current[currentSquareRef.current]["dailyImagery"][currentSquareDateIdRef.current] = {}
        }
        if(basemapImageryComparisonObjRef.current[currentSquareRef.current]["dailyImagery"][currentSquareDateIdRef.current] == null){
            basemapImageryComparisonObjRef.current[currentSquareRef.current]["dailyImagery"][currentSquareDateIdRef.current] = {}
        }

        return new Promise(async (resolve, reject) => {

            try{
                if(type == "dailyImagery"){

                    if(basemapImageryComparisonObjRef.current[currentSquareRef.current]["dailyImagery"][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] != null){
                        const imageData = basemapImageryComparisonObjRef.current[currentSquareRef.current]["dailyImagery"][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current]
                        resolve(imageData)
                    }else{
                        extractImageDataFromUrl(url).then((imageData) => {
                            basemapImageryComparisonObjRef.current[currentSquareRef.current]["dailyImagery"][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = imageData
                            resolve(imageData)
                        }).catch((err) => {
                            reject(err)
                        });
                    }
                }else if(type == "yearlyImagery"){
                    if(basemapImageryComparisonObjRef.current[currentSquareRef.current]["yearlyImagery"][key] != null){
                        const imageData = basemapImageryComparisonObjRef.current[currentSquareRef.current]["yearlyImagery"][key]
                        resolve(imageData)
                    }else{
                        extractImageDataFromUrl(url).then((imageData) => {
                            basemapImageryComparisonObjRef.current[currentSquareRef.current]["yearlyImagery"][key] = imageData
                            resolve(imageData)
                        }).catch((err) => {
                            reject(err)
                        });
                    }
                }

            }catch(err){
                console.error(err)
                reject(err)
            }
            

        })

    }

    function renderBasemapImageryComparison(){
        try{
            
            // get the current date
            const currentDate = new Date(dateSliderValueRef.current)
            
            var closestElement = null
            var closestDiff = null

            Object.values(squareDataYearlyObjRef.current[currentSquareRef.current]).forEach((element) => {
                const diff = Math.abs(currentDate.getTime() - element.date.getTime())
                if(closestDiff == null || diff < closestDiff){
                    closestDiff = diff
                    closestElement = element
                }
            })

            if(closestElement != null){
                
                const currentSquareDateImage = squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current]                

                if(currentSquareDateImage != null && closestElement.url != null && currentSquareDateImage.imagery?.rawRGB != null){

                    // get or fetch the yearly and daily imagery
                    const promiseArray = [
                        fetchImageData({
                            "type": "yearlyImagery",
                            "key": closestElement.key,
                            "url": closestElement.url,
                        }),
                        fetchImageData({
                            "type": "dailyImagery",
                            "key": currentSquareDateImage.dateId,
                            "url": currentSquareDateImage.imagery?.rawRGB
                        })
                    ]

                    Promise.all(promiseArray).then((results) => {

                        // if both results are arrays and they are the same length then same length
                        const yearlyImageryData = results[0]
                        const dailyImageryData = results[1]

                        if(yearlyImageryData?.rgbArray?.length === dailyImageryData?.rgbArray?.length){
                            const url = processBasemapDifferences(yearlyImageryData, dailyImageryData)
                            setCurrentSquareShowGeneralBasemapDiff(true)
                            setCurrentSquareBasemapImageryComparisonUrl(url)
                            setViewportReRendererId(Math.random() * 1000) // trigger a re-render of the viewport
                            
                        }


                    })

                    
                      
                }

            }

            
    
    
        }catch(err){
            console.error(err)
        }
        
    }
    
    function handleDateSliderChange(_value){

        return new Promise(async (resolve, reject) => {
            try{
                //find the element in the squareImageryRef.current[selectedSquareRef] that has the displayDate.getTime() == _value
                const tempSelectedSquareTimeMappedDates = squareDataObjRef.current[currentSquareRef.current]
                if(tempSelectedSquareTimeMappedDates == null){
                    resolve()
                }
        
                const tempSelectedSquareTimeMappedDatesArray = Object.values(tempSelectedSquareTimeMappedDates).filter(element => Object.values(element).length > 0).map(element => Object.values(element))
                const tempSelectedSquareTimeMappedDateArray = tempSelectedSquareTimeMappedDatesArray.find((dateObj) => dateObj[0].endDate.getTime() == _value).filter((imageObj) => imageObj.imageId != "undefined")
                
                if(tempSelectedSquareTimeMappedDateArray != null){
                    try{

                        currentSquareDateIdRef.current = tempSelectedSquareTimeMappedDateArray[0].dateId
                        setCurrentSquareDateId(tempSelectedSquareTimeMappedDateArray[0].dateId)
                        
                        currentSquareDateIdSelectedImageRef.current = tempSelectedSquareTimeMappedDateArray[0].imageId
                        setCurrentSquareDateIdImageSelectedOption(currentSquareDateIdSelectedImageRef.current)

                        currentSquareDateIdImageSelectOptionsRef.current = tempSelectedSquareTimeMappedDateArray;
                        setCurrentSquareDateIdImageSelectOptions(tempSelectedSquareTimeMappedDateArray)
                        

                        dateSliderValueRef.current = _value
                        setDateSliderValue(_value)
                        setTimeout(() => {
                            setRefreshKey(refreshKey + 1)
                        
                        }, 500);

                        
                        renderDateSliderStyle()

                        updateImageryLoadState()
                        
                        setCurrentSquareShowGeneralBasemapDiff(false)
                        renderBasemapImageryComparison()
                        

                        reRenderPolygons(true, true)
                        
                        resolve()
                    
                    }catch(err){
                        console.error(err)
                        createAlert('error', "Error setting date")
                        resolve()
                    }
        
                }else{
                    setCurrentSquareDateIdImageSelectOptions([])
                    createAlert('error', 'Date not found')
                    resolve()
                }
            }catch(err){
              console.error(err)
              createAlert('error', 'Error setting date')
              resolve()
            }
          });

    }
    
    function getAllActiveSquares(){
        //check which folders exist in the AdminData folder
        const AdminDataFolder = ref(storage, `AdminData`)
        // get all the folders in the detectedChangesRef
        listAll(AdminDataFolder).then((res) => {
        var tempSquareElements = {}
        res.prefixes.forEach(prefix => {
            tempSquareElements[prefix.name] = createAdminSquareFromId(prefix.name)
        });
        squaresRef.current = tempSquareElements

        requestViewportRender()
        })
        .catch((error) => {
            console.error(error)
            createAlert('error', error)
        });
    }

    function getSquareData(_squareId){
        return new Promise(async (resolve, reject) => {

            // get all Raw RGB imagery
            // get all Raw Cloud masked JSON
            // get all Cloud Masked Imagery
            const rawRGBImageryFolder = ref(storage, `/AdminData/${_squareId}/Imagery/PNG/UnprocessedPNG/SingleImage/RawImage/RGB`)
            const rawCloudMaskJSONFolder = ref(storage, `/AdminData/${_squareId}/Imagery/PNG/UnprocessedPNG/SingleImage/RawImage/CloudMask`)
            const cloudMaskedImageryFolder = ref(storage, `/AdminData/${_squareId}/Imagery/PNG/UnprocessedPNG/SingleImage/CloudMaskedRGBA`)
            const squareYearlyImageryFolder = ref(storage, `/AdminData/${_squareId}/Imagery/PNG/Yearly/RGBA`)
            
                        

            const folderPromiseArray = [listAll(rawRGBImageryFolder), listAll(rawCloudMaskJSONFolder), listAll(cloudMaskedImageryFolder), listAll(squareYearlyImageryFolder)]
            const folderRefArray = ["allRawRGBImagery", "allRawCloudMaskJSON", "allCloudMaskedImagery", "allYearlyImagery"]
            
            const folderResponseArray = await Promise.all(folderPromiseArray)

            const elementPromiseArray = []
            const elementRefArray = []
            folderResponseArray.forEach((folder, index) => {
                folder.items.forEach((itemRef) => {
                    //get the ddwnload url for the item
                    elementPromiseArray.push(getDownloadURL(itemRef))
                    elementRefArray.push({
                        itemRef: itemRef,
                        folder: folderRefArray[index]
                    })
                })
            })


            const downloadElements = await Promise.all(elementPromiseArray)
        
            var responseDataObj = {
                timeMappedData: {},
                yearlyBasemaps: {}
            }

            downloadElements.forEach((url, index) => {

                const itemRef = elementRefArray[index].itemRef
                const folder = elementRefArray[index].folder

                const fileName = itemRef.name.split('.')[0]
                // take the first 13 characters of the file name                
                const endDateId = fileName.slice(14, 24)
                const endDateYear = endDateId.split('-')[0]
                const endDateMonth = endDateId.split('-')[1]
                const endDateDay = endDateId.split('-')[2]
                const endDate = new Date(Date.UTC(endDateYear, endDateMonth - 1, endDateDay))
                const imageId = fileName.split('-')[5]

                if(imageId != "undefined"){


                    if(responseDataObj["timeMappedData"][endDateId] == null){
                        responseDataObj["timeMappedData"][endDateId] = {}
                    }



                    if(responseDataObj["timeMappedData"][endDateId][imageId] == null && folder != "allYearlyImagery"){
                        responseDataObj["timeMappedData"][endDateId][imageId] = {
                            dateId: endDateId,
                            dateRange: `${endDateId}`,
                            endDate: endDate,
                            endDateId: endDateId,
                            displayDate: endDate,
                            squareId: _squareId,
                            imageId: imageId,
                            json: {
                            },
                            imagery: {
                            },                        
                            loaded: false
                        }

                    }

                    if(folder == "allRawRGBImagery"){
                        responseDataObj["timeMappedData"][endDateId][imageId].imagery.rawRGB = url
                    }else if(folder == "allRawCloudMaskJSON"){

                        const jsonType = fileName.split('-')[6]
                        


                        if(responseDataObj["timeMappedData"][endDateId][imageId].json.rawCloudMask == null){
                            responseDataObj["timeMappedData"][endDateId][imageId].json.rawCloudMask = {}
                        }

                        responseDataObj["timeMappedData"][endDateId][imageId]["json"]["rawCloudMask"][jsonType] = {
                            url: url,
                            fileName: itemRef.name,
                            imageId: imageId,
                            jsonType: jsonType
                        }
                    }
                    else if(folder == "allCloudMaskedImagery"){
                        responseDataObj["timeMappedData"][endDateId][imageId].imagery.cloudMasked = url
                    }else if(folder == "allYearlyImagery"){

                        const splitString = fileName.split('-')
                        var fileKey = ""
                        var yearlyImageDate = null
                        if(splitString[2] == "General"){
                            yearlyImageDate = new Date("1/1/2000")
                            fileKey = "General"
                        }else{
                            yearlyImageDate = new Date(splitString[2] + "-" + splitString[3] + "-" + splitString[4])
                            fileKey = `${splitString[2]}-${splitString[3]}-${splitString[4]}`
                        }

                        
                        responseDataObj["yearlyBasemaps"][fileKey] = {
                            url: url,
                            key: fileKey,
                            fileName: itemRef.name,
                            date: yearlyImageDate
                        }
                    }
                }
            });

            resolve(responseDataObj)
            
            
        })
    }

    function selectSquare(_squareId){

        return new Promise(async (resolve, reject) => {
            //only update the selected square if it's different than the current and the user is not processing an analytic
            if(_squareId != currentSquareRef.current){
      
                //check if there is a brightness setting for the lot in local storage
                var brightness = defualtImageryBrightness
                var localStorageBrightness = localStorage.getItem(`imageryBrightness-adminMapLabeling-${_squareId}`)
                if(localStorageBrightness != null){
                    brightness = parseFloat(localStorageBrightness)
                }

                if(squareJSONCloudMaskRef.current[_squareId] == null){
                    squareJSONCloudMaskRef.current[_squareId] = {}
                }                
                if(squareJSONCloudMaskMLRef.current[_squareId] == null){
                    squareJSONCloudMaskMLRef.current[_squareId] = {}
                }
                if(squareJSONMetaDataRef.current[_squareId] == null){
                    squareJSONMetaDataRef.current[_squareId] = {}
                }
                

                //resets everything
                setCurrentSquare(null)
                setCurrentSquareVisiblePngs(null)
                setCurrentSquareObject(null)

                cloudMaskLocationHistoryRef.current = []
                cloudMaskLocationHistoryIndexRef.current = -1

                cloudMaskChangeHistoryRef.current = []
                cloudMaskChangeHistoryIndexRef.current = -1

                setCurrentSquareCloudMaskPolygons([])
                setCurrentSquareGeneralSquareBasemapUrl(null)

                //instantiates the square
                currentSquareRef.current = _squareId
                setCurrentSquare(_squareId)

                updateImageryBrightness(brightness)


                const square = squaresRef.current[_squareId]
                    
                if(_squareId != null && square != null){
                    // if the square has not been processed yet
                    if(squareDataObjRef.current[_squareId] == null){
                        // create the square imagery object
                        squareDataObjRef.current[_squareId] = {}
                        const data = await getSquareData(_squareId)

                        squareDataObjRef.current[_squareId] = data["timeMappedData"]
                        squareDataYearlyObjRef.current[_squareId] = data["yearlyBasemaps"]
                        
                    }

                    // this is a thing for doing an imagery compare
                    // if(squareImageryGeneralBasemapRef.current[_squareId] == undefined){
                        
                    //     const generalSquareBasemap = ref(storage, `/AdminData/${_squareId}/Imagery/PNG/Yearly/RGBA/${_squareId}-General-rgba.png`)
                    //     // check if the square has a general basemap                        
                        
                    //     getDownloadURL(generalSquareBasemap)
                    //     .then((url) => {
                    //         const urlString = url.toString()
                    //         squareImageryGeneralBasemapRef.current[_squareId] = urlString
                    //         setCurrentSquareGeneralSquareBasemapUrl(urlString)
                    //         setViewportReRendererId(Math.random() * 1000)
                    //     })
                    //     .catch((err) => {
                    //         console.error(err)
                    //         squareImageryGeneralBasemapRef.current[_squareId] = ""
                    //     });                            
                    
                    // }else{
                    //     setCurrentSquareGeneralSquareBasemapUrl(squareImageryGeneralBasemapRef.current[_squareId])
                    //     setViewportReRendererId(Math.random() * 1000)
                    // }

                    


                    const tempCurrentSquareImageryObj = squareDataObjRef.current[_squareId]
                    // update the DOM
                    currentSquareImageryObjRef.current = tempCurrentSquareImageryObj
                    setCurrentSquareImageryObj(tempCurrentSquareImageryObj)                    


                    cloudMaskChangeHistoryRef.current = []
                    cloudMaskChangeHistoryIndexRef.current = -1

                    cloudMaskLocationHistoryRef.current = []
                    cloudMaskLocationHistoryIndexRef.current = -1

                    setCurrentSquareCloudMaskPolygons([])


                    if(dateSliderMarksLoadStateRef.current[_squareId] == null){
                        dateSliderMarksLoadStateRef.current[_squareId] = {}
                    }
                    
                    
                    // iterate over all the dates and check if the cloud maskpng exists

                    Object.keys(squareDataObjRef.current[_squareId]).forEach((tempDateId) => {
                        const dateObj = squareDataObjRef.current[_squareId][tempDateId]
                        // check if all the elements are loaded
                        const allLoaded = Object.values(dateObj).map((element) => {
                            return element?.json?.rawCloudMask?.Official != null
                        }).every((element) => element === true)
                        if(dateObj?.json?.rawCloudMask?.Official != null){
                            dateSliderMarksLoadStateRef.current[_squareId][tempDateId] = allLoaded
                        }
                    })

                    // render the date slider
                    renderDateSlider()

                    currentSquareObjectRef.current = square
                    setCurrentSquareObject(square)
                    renderDateSliderStyle()

                    resolve()

                }else{
                    reRenderPolygons(true, true)

                    resolve()
                }
            }
        });
    }


    function updateImageryBrightness(_brightness){


        if(_brightness > 0 && _brightness <= 100){
          setImageryBrightness(_brightness)
    
          document.getElementById('dynamicCSS').innerHTML = `
            img[src*="firebase"] {
              filter: brightness(${Math.sqrt(_brightness)});
            }
          `;
          
          //store the brightness in local storage tied to the orgId and lotId
          localStorage.setItem(`imageryBrightness-adminMapLabeling-${currentSquareRef.current}`, _brightness)
    
        }
    
      }

    
    function updateCloudMaskThreshold(_cloudMaskThreshold){

        if(_cloudMaskThreshold >= -20 && _cloudMaskThreshold <= 20){

            cloudMaskThresholdRef.current = _cloudMaskThreshold
            setCloudMaskThreshold(_cloudMaskThreshold)

            
            // re-render the cloud mask theshold image
            renderBasemapImageryComparison()
                
        }



    }



    function updateCoordinateSpotSquareCloudMask(_lng, _lat, _isCloud){
        try{
            const squareLatLngs = squareJSONMetaDataRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current]?.squareLatLngs
            const latArray = Object.entries(squareLatLngs['lats'] ?? {})
            const lngArray = Object.entries(squareLatLngs['lngs'] ?? {})

            //find the first element that is greater than the lat, get the index and then subtract 1
            const closestLatIndex = latArray.findIndex((element, index) => {
                return element[1] < _lat 
            }) - 1
            const closestLngIndex = lngArray.findIndex((element, index) => {
                return element[1] > _lng 
            }) - 1

            // get the sixteen squares that are closest to the index 
            const indexArray = [
                                       [-2,-3],[-1,-3],[0,-3],[1,-3],[2,-3],
                                [-3,-2],[-2,-2],[-1,-2],[0,-2],[1,-2],[2,-2],[3,-2],
                                [-3,-1],[-2,-1],[-1,-1],[0,-1],[1,-1],[2,-1],[3,-1],
                                [-3,0],[-2,0],[-1,0],[0,0],[1,0],[2,0],[3,0],
                                [-3,1],[-2,1],[-1,1],[0,1],[1,1],[2,1],[3,1],
                                [-3,2],[-2,2],[-1,2],[0,2],[1,2],[2,2],[3,2],
                                       [-2,3],[-1,3],[0,3],[1,3],[2,3]

                            ]

            // const indexArray = [[-2, -2], [-2, -1], [-2, 0], [-2, 1], [-2, 2], [-1, -2], [-1, -1], [-1, 0], [-1, 1], [-1, 2], [0, -2], [0, -1], [0, 0], [0, 1], [0, 2], [1, -2], [1, -1], [1, 0], [1, 1], [1, 2], [2, -2], [2, -1], [2, 0], [2, 1], [2, 2]]
            const tempArray = []

            indexArray.forEach((indexArray) => {

                try{
                    const latIndex = closestLatIndex + indexArray[0]
                    const lngIndex = closestLngIndex + indexArray[1]

                    const closestLat = latArray[closestLatIndex + indexArray[0]]?.[0]
                    const closestLng = lngArray[closestLngIndex + indexArray[1]]?.[0]


                    if(closestLat != undefined && closestLng != undefined && latIndex < latArray.length-1 && lngIndex < lngArray.length-1){

                        tempArray.push({
                            maskKey: `${closestLat}-${closestLng}`,
                            isCloud: _isCloud,
                            isVerified: true
                        })
                    }
                }catch(err){
                    console.error(err)
                }
            })

            squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = tempArray
            
            reRenderPolygons(true, true)
        }catch(err){
            console.error(err)
        }
    }

    function resetPolygonToolMask(){
        try{
            if(imageryReviewMaskObjectRef.current[currentSquareRef.current] != null){
                if(imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] != null){
                    imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].polygon = []
                    setCurrentSquarePolygonToolMask(imageryReviewMaskObjectRef.current?.[currentSquareRef.current]?.[currentSquareDateIdRef.current]?.[currentSquareDateIdSelectedImageRef.current])
                    setViewportReRendererId(Math.random() * 1000)
                }
            }
        }catch(err){
            console.error(err)
        }
    }


    function addCoordinatesToPolygonToolMask(_lng, _lat){

        // make sure imageryReviewMaskObjectRef.current has a key for the currentSquare
        if(imageryReviewMaskObjectRef.current[currentSquareRef.current] == null){
          imageryReviewMaskObjectRef.current[currentSquareRef.current] = {}
        }
        // make sure imageryReviewMaskObjectRef.current[currentSquare] has a key for the current date
        if(imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current] == null){
          imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current] = {}
        }   
        // make sure imageryReviewMaskObjectRef.current[currentSquare] has a key for the selected image
        if(imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] == null){
            imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = {}
          }   
  

        

        // check if selectedPolygonKeyRef.current is null
        if(imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].polygon == null){
            // add a random key

            imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = {
                polygon: [{lng: _lng, lat: _lat}],
            }

            
        }else{
            // add the coordinates to the polygon
            imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].polygon.push({lng: _lng, lat: _lat})
        }

        setCurrentSquarePolygonToolMask(imageryReviewMaskObjectRef.current?.[currentSquareRef.current]?.[currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current])
        setViewportReRendererId(Math.random() * 1000)
        
    }

    function updatePolygonToolMask(_maskArray){
        try{
            // get polygon path from currentSquarePolygonToolMaskRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdImageSelectedOptionRef.current]
            const polygonPath = currentSquarePolygonToolMaskRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].getPath().getArray().map((latLng) => {
                return {lng: latLng.lng(), lat: latLng.lat()}
            })
            imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].polygon = polygonPath
            setCurrentSquarePolygonToolMask(imageryReviewMaskObjectRef.current?.[currentSquareRef.current]?.[currentSquareDateIdRef.current]?.[currentSquareDateIdSelectedImageRef.current])
            setViewportReRendererId(Math.random() * 1000)
            
        }catch(err){
            console.error(err)
        }
    }

    
    function updatePolygon(_key){

        const newPolygon = imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current][_key].ref.getPath().getArray().map((latLng) => {
            return {lng: latLng.lng(), lat: latLng.lat()}
        })
        // update the polygon in the object
        imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current][_key].polygon = newPolygon

        setCurrentSquarePolygonToolMask(imageryReviewMaskObjectRef.current?.[currentSquareRef.current]?.[currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current])
    }

   

    function requestViewportRender(){
        if (lastViewportRenderTimeoutRef.current) {
          clearTimeout(lastViewportRenderTimeoutRef.current)
        }
        if(Date.now() - lastViewportRenderRef.current > 250){
          renderViewport()
        }else{
          lastViewportRenderTimeoutRef.current = setTimeout(() => {
            renderViewport()
            lastViewportRenderTimeoutRef.current = null
          }, 250)
        }
      }

    function renderDateSliderStyle(){
        
        try{

            const loadState = calculateLoadState(squareDataObjRef.current[currentSquareRef.current])


            const tempSelectedDate = dateSliderMarksRef.current?.marks.find(mark => mark.value == dateSliderValueRef.current)
            if(tempSelectedDate != null){ 
                const tempStyles = calculateDateMarkStyles(dateSliderMarksRef.current?.marks, {[currentSquareRef.current]: loadState}, tempSelectedDate.dateElement.dateId, theme, false)
                setDateSliderMarksLoadStateStyle(tempStyles)    

            }    
        }catch(err){
            console.error(err)
            createAlert('error', 'Error rendering date slider style')
        }
    }

    function calculateLoadState(_squareDatesObj){

        const loadState = {}

        Object.values(_squareDatesObj).forEach((squareDateObj) => {
            try{
                
                const elementLoadStateArray = Object.values(squareDateObj).map((squareDateImageObject) => {
                    if(squareDateImageObject?.imagery?.cloudMasked != null){
                        return true
                    }else if(squareDateImageObject?.json?.rawCloudMask?.Official != null){
                        return true
                    }else{
                        return false
                    }
                })

                // check if all the elements are true
                const allLoaded = elementLoadStateArray.every((element) => element === true)
                if(allLoaded == true){

                    const dateId = Object.values(squareDateObj)[0].dateId
                    loadState[dateId] = true
                    

                    
                }
            }catch(err){
                console.error(err)
            }

        })

        return loadState
    }

    function updateImageryLoadState(){
        const tempSelectedSquareTimeMappedDateArray = squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current]

        currentSquareImageryObjRef.current = tempSelectedSquareTimeMappedDateArray
        setCurrentSquareImageryObj(currentSquareImageryObjRef.current)
        squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].loaded = true
        const tempCurrentSquareVisiblePngs = Object.values(squareDataObjRef.current[currentSquareRef.current])
                                                    .map(dateObj => {
                                                        return Object.values(dateObj)
                                                    })
                                                    .flat(Infinity)
                                                    .filter((imageObj) => {
                                                        return imageObj.loaded
                                                    })

        //check if the state has changed
        if(JSON.stringify(currentSquareVisiblePngs) !== JSON.stringify(tempCurrentSquareVisiblePngs)){
            setCurrentSquareVisiblePngs(tempCurrentSquareVisiblePngs)
        }

    }


    function processWorkingMaskData(_workingMask, _MLMasksObject){

        const processingSquareCLoudMaskTouchedSquaresObject = {}

        const falseNegativeKeys = {}
        const lastNMonthIds = calculateLastNMonthIds(12)

        _workingMask.forEach((row, rowIndex) => {                        
            row.forEach((cell, columnIndex) => {
                const maskKey = `${rowIndex * settings.ANALYTICS_PIXELS_PER_SQUARE}-${columnIndex * settings.ANALYTICS_PIXELS_PER_SQUARE}`
                var isCloud = false
                var isVerified = false
                var isMLFalsePositive = false
                var isMLFalseNegative = false

                
                var falsePositive = false

                if(cell == 0){
                    // negative
                    // do not count the square
                    // check if the cell is a ML false negative
                    if(_MLMasksObject?.ml_notCloudFalseNegative != null && _MLMasksObject?.ml_notCloudFalseNegative[rowIndex][columnIndex] > cloudMaskThresholdRef.current){                        
                        //add the square directly nsew to the falseNegativeKeys array
                        falseNegativeKeys[maskKey] = true
                    }


                }else if(cell == 1){
                    // positive
                    isCloud = true
                    isVerified = false
                    
                    // check if the cell is a ml false positive
                    if(_MLMasksObject?.ml_cloudFalsePositive != null && _MLMasksObject?.ml_cloudFalsePositive[rowIndex][columnIndex] > cloudMaskThresholdRef.current){                        
                        isMLFalsePositive = true
                        
                    }else{
                        // check if the cell is a possible false positive
                        lastNMonthIds.forEach((dateId) => {

                            Object.keys(squareJSONCloudMaskRef.current[currentSquareRef.current][dateId]).forEach((imageId) => {
                                try{
                                    const lastSixMonthMask = squareJSONCloudMaskRef.current?.[currentSquareRef.current]?.[dateId]?.[imageId]?.["official"]
                                    if(lastSixMonthMask != null && lastSixMonthMask != undefined){
                                        const lastSixMonthCell = lastSixMonthMask[rowIndex][columnIndex]
                                        // if the cell has been marked as a verified negative in the last 6 months
                                        if(lastSixMonthCell == 4){
                                            // verified false Positive
                                            falsePositive = true                                                                
                                        }
                                    }    

                                }catch(err){
                                    console.error(err)
                                }
                            })                                        
                        });
                    }

                    
                    
                }else if(cell == 2){
                    // verified negative
                    isCloud = false
                    isVerified = true
                }else if(cell == 3){
                    // verified positive
                    isCloud = true
                    isVerified = true
                }else if(cell == 4){
                    // verified false positive
                    isCloud = false
                    isVerified = true
                    falsePositive = true
                }

                if(processingSquareCLoudMaskTouchedSquaresObject[maskKey] == null || isVerified == true){

                    processingSquareCLoudMaskTouchedSquaresObject[maskKey] = {
                        "maskKey": maskKey,
                        "isCloud": isCloud,
                        "isVerified": isVerified,
                        "falsePositive": falsePositive,
                        "isMLFalsePositive": isMLFalsePositive,
                        "isMLFalseNegative": isMLFalseNegative
                    }       

                    
                }
            })                    
        });

        // iterate through all the falseNegativeKeys and set the isMLFalseNegative to true
        Object.keys(falseNegativeKeys).forEach((maskKey) => {
            try{
                const singleOffset = [
                    [-1,-1], [0,-1], [1,-1],
                    [-1, 0],         [1, 0],
                    [-1, 1], [0, 1], [1, 1]
                ]

                const maskKeyArray = maskKey.split('-')
                const rowIndex = parseInt(maskKeyArray[0]) / settings.ANALYTICS_PIXELS_PER_SQUARE
                const columnIndex = parseInt(maskKeyArray[1]) / settings.ANALYTICS_PIXELS_PER_SQUARE

                // iterate through the singeOffset array and count how many false negatives are in the surrounding squares
                // if there are 2 or more, set the isMLFalseNegative to true
                var connectedFalseNegatives = 0
                singleOffset.forEach((offset) => {
                    const newRowIndex = rowIndex + offset[1]
                    const newColumnIndex = columnIndex + offset[0]
                    const tempMaskKey = `${newRowIndex * settings.ANALYTICS_PIXELS_PER_SQUARE}-${newColumnIndex * settings.ANALYTICS_PIXELS_PER_SQUARE}`
                    if(processingSquareCLoudMaskTouchedSquaresObject[tempMaskKey] != null){
                        connectedFalseNegatives++                        
                    }
                })

                if(connectedFalseNegatives > 3){
                    const offsetValues = [

                    [-1, -1],  [0, -1], [1, -1],
                    [-1,  0],  [0,  0], [1,  0],
                    [-1,  1],  [0,  1], [1,  1],
                      
                    ]
                    offsetValues.forEach((offset) => {
                        const newRowIndex = rowIndex + offset[1]
                        const newColumnIndex = columnIndex + offset[0]
                        const tempMaskKey = `${newRowIndex * settings.ANALYTICS_PIXELS_PER_SQUARE}-${newColumnIndex * settings.ANALYTICS_PIXELS_PER_SQUARE}`
                        falseNegativeKeys.push(tempMaskKey)        
                    })

                    if(processingSquareCLoudMaskTouchedSquaresObject[maskKey] != null){
                        processingSquareCLoudMaskTouchedSquaresObject[maskKey].isMLFalseNegative = true
                    }
                }

                
            }catch(err){
                //do nothing                
            }
        })

        return processingSquareCLoudMaskTouchedSquaresObject

    }

    function calculateLastNMonthIds(_numberOfMonths){
        try{
            const tempSelectedSquareTimeMappedDatesArray = Object.values(squareDataObjRef.current[currentSquareRef.current])
            const lastNMonthIds = tempSelectedSquareTimeMappedDatesArray.filter((dateObj) => {
                try{
                    const imageObj = Object.values(dateObj)[0]
                    
                    const dateObjEndDate = imageObj.endDate.getTime()
                    const dateSliderCurrentDate = dateSliderValueRef.current
                    const dateSliderNMonthsAgo = dateSliderValueRef.current - (_numberOfMonths * 30 * 24 * 60 * 60 * 1000)

                    return dateObjEndDate <= dateSliderCurrentDate && dateObjEndDate >= dateSliderNMonthsAgo
                }catch(err){
                    console.error(err)
                    return false
                }

            }).map((dateObj) => {
                const imageObj = Object.values(dateObj)?.[0]
                if(imageObj == null){
                    return null
                }else{
                    return imageObj.dateId
                }
            })
            .filter(e => e != null)

            return lastNMonthIds

        }catch(err){
            return []
        }

        

    }

    async function selectImageForDataProcessing(){
        try{

            // load the imagery data
            currentSquareShowGeneralBasemapRef.current = false
            currentSquareShowPolygonsRef.current = true
            setCurrentSquareShowGeneralBasemap(false)
            setCurrentSquareShowPolygons(true)

            
            try{
                // only fetch data if the current square date has json data

                const currentElement = squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current]

                if(Object.keys(currentElement.json).length > 0 && squareCloudMaskTouchedSquaresRef.current?.[currentSquareRef.current]?.[currentSquareDateIdRef.current]?.[currentSquareDateIdSelectedImageRef.current] == null){
                    const numberOfHistoricalFalsePositiveMonths = 12
                    // get the dateId for the tempSelectedSquareTimeMappedDatesArray elements that are within the last numberOfHistoricalFalsePositiveMonths(12) months
                    const lastNMonthIds = calculateLastNMonthIds(numberOfHistoricalFalsePositiveMonths)
                    
                    // fetch the cloud mask data for the last ${numberOfHistoricalFalsePositiveMonths} months plus the current 
                    await Promise.all(lastNMonthIds.map(async (dateId) => {
                        await fetchSquareDateMaskData(dateId)
                    }))

                    

                    // fetch the current dateId cloud mask
                    await fetchSquareDateMaskData(currentElement.dateId)
                    
                    const workingMask = squareJSONCloudMaskRef.current[currentSquareRef.current][currentElement.dateId][currentElement.imageId]["working"]
                    const workingMetaData = squareJSONMetaDataRef.current[currentSquareRef.current][currentElement.dateId][currentElement.imageId]


                    var tempSquareCLoudMaskTouchedSquaresObject = {}
                    
                    if(![workingMask, workingMetaData].includes(undefined)){
                    
                        tempSquareCLoudMaskTouchedSquaresObject = processWorkingMaskData(workingMask, squareJSONCloudMaskMLRef.current[currentSquareRef.current][currentElement.dateId][currentElement.imageId])

                        
                    }

                    if(squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current] == null){
                        squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current] = {}
                    }
                    if(squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current][currentSquareDateIdRef.current] == null){
                        squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current][currentSquareDateIdRef.current] = {}
                    }
                                        
                    squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = Object.values(tempSquareCLoudMaskTouchedSquaresObject)
                
                }

                cloudMaskChangeHistoryRef.current = []
                cloudMaskChangeHistoryIndexRef.current = -1
                
                reRenderPolygons(true, true)
                
            }catch(err){
                console.error(err)
                createAlert('error', 'Error calculating cloud mask polygons')
            }

        }catch(err){
            console.error(err)
            createAlert('error', 'Error selecting image for data processing')
        }

    }
    
    
    function renderViewport(){
        try{
          lastViewportRenderRef.current = Date.now()
    
          if (mapRef.current) {
    
            const bounds = mapRef.current.getBounds()
            const northWest = bounds.getNorthEast()
            const southEast = bounds.getSouthWest()

            // var tempVisibleLotsArray = []
            var tempVisibleSquaresArray = []
            
            if(currentSquareRef.current == null){
          
                // tempVisibleSquaresArray = Object.keys(squaresRef.current).filter(key => {
                //   const square = squaresRef.current[key]
                //   //if any of the bounds are within the viewport
                //   if(square.bounds.south < northWest.lat() && square.bounds.north > southEast.lat() && square.bounds.east > southEast.lng() && square.bounds.west < northWest.lng()){
                //     return true
                //   }else{
                //     return false
                //   }          
                // })

                tempVisibleSquaresArray = Object.keys(squaresRef.current)
      
                // tempVisibleLotsArray = Object.keys(lotsRef.current).filter(key => {
                //   const lot = lotsRef.current[key]
                //   //if any of the bounds are within the viewport
                //   if(lot.bounds.south < northWest.lat() && lot.bounds.north > southEast.lat() && lot.bounds.east > southEast.lng() && lot.bounds.west < northWest.lng()){
                //     return true
                //   }else{
                //     return false
                //   }          
                // })
      
                // // only rerender if the visibleLotsArray has changed
                // if(JSON.stringify(visibleLotsArray) !== JSON.stringify(tempVisibleLotsArray)){
                //   setVisibleLotsArray(tempVisibleLotsArray)
                // }
      
                if(JSON.stringify(visibleSquaresArray) !== JSON.stringify(tempVisibleSquaresArray)){
                  setVisibleSquaresArray(tempVisibleSquaresArray)
                }
                
              }
            
            
          }
        }catch(err){
          console.error(err)
        }
    
        
      }
    
    function renderDateSlider(){

        const currentSquareImageryObj = squareDataObjRef.current[currentSquareRef.current]
        if(currentSquareImageryObj != null){

            const dateArray = Object.values(currentSquareImageryObj).filter(element => Object.values(element).length > 0).map(element => Object.values(element)[0]).map(dateObj => dateObj.endDate.getTime())
            const minDate = Math.min(...dateArray)
            const maxDate = Math.max(...dateArray)
            setDateSliderMinDate(minDate)
            setDateSliderMaxDate(maxDate)
        
            const timeMappedDataObj = {
                [currentSquareRef.current]: {
                timeData: currentSquareImageryObj
                }
            }
        
            const tempDateSliderMarks = calculateDateSliderMarks(timeMappedDataObj, "monthly", "AdminImageryReview")
        
        
            dateSliderMarksRef.current = tempDateSliderMarks
            setDateSliderMarks(tempDateSliderMarks)
        
            //if the dateSliderValue is null, or the value is not one of the dates in the currentSquareImageryObj object, set the value to the maxDate
            if(dateSliderValue == null || !dateArray.includes(dateSliderValue)){
                handleDateSliderChange(maxDate)
            }else{
                //if changing from one square to another but with the same date get the new squares info using the dateslider change
                handleDateSliderChange(dateSliderValue)
            }
        
        }
    
    }

    function panToBounds(_bounds, _updateHistory){
        //ensure west is less than east
        if(_bounds.west > _bounds.east){
          const temp = _bounds.west
          _bounds.west = _bounds.east
          _bounds.east = temp
        }
        //ensure south is less than north
        if(_bounds.south > _bounds.north){
          const temp = _bounds.south
          _bounds.south = _bounds.north
          _bounds.north = temp
        }
    
        // isFittingBoundsRef.current = true
    
        if(mapRef.current){
          
          // const handleZoomChanged = () => {
    
          //   setTimeout(() => {
          //     if (mapRef.current.getZoom() > maxZoomRef.current) {
          //       mapRef.current.setZoom(maxZoomRef.current);
          //     }
          //     isFittingBoundsRef.current = false
                
          //   }, 100);
          
          // };
    
          // mapRef.current.addListener('zoom_changed', handleZoomChanged);
    
          mapRef.current.fitBounds(_bounds)
    
          // setLocation({lat: (_bounds.north + _bounds.south) / 2, lng: (_bounds.east + _bounds.west) / 2})
    
          if(_updateHistory){
            addHistoryElement(_bounds)
          }
        }
      }

    


    function paintBucketUpdateCloudMaskPolygon(_polygonId, _isCloud){
        try{

            const clickedPolygon = squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].find((element) => element.polygonKey == _polygonId)
            const clickedPolygonIndex = squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].findIndex((element) => element.polygonKey == _polygonId)            

            if(clickedPolygon != null){
                // see if isCloud is different
                // if(clickedPolygon.isCloud != _isCloud || !clickedPolygon.isVerified){

                // squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdImageSelectedOptionRef.current][clickedPolygonIndex].isCloud = _isCloud
                // squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdImageSelectedOptionRef.current][clickedPolygonIndex].isVerified = true


                const affectedIndexes = Object.keys(squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current][clickedPolygonIndex].keys)
                const tempArray = []

                var isFalsePositive = clickedPolygon.falsePositive == true;
                //if the polygon is already verified as not a cloud and the user is trying to mark it as not a cloud then set it also as false positive
                if(!clickedPolygon.isCloud && clickedPolygon.isVerified && !_isCloud){
                    if(clickedPolygon.falsePositive){
                        // update all of the unverified clouds to verified not clouds and all of the verified not clouds to false positive
                        // check all of the existing polygons and see if the turf polygon intersects
                        const clickedTurfPolygon = clickedPolygon.turfPolygon;
                        squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].forEach((element, index) => {
                            if(element.polygonKey != _polygonId && element.turfPolygon != null){
                                if(turf.booleanIntersects(clickedTurfPolygon, element.turfPolygon)){
                                    
                                    if(!element.isVerified && element.isCloud){
                                        // if the polygon is not verified but is a cloud then set it as verified not a cloud
                                        const tempElement = {...element}

                                        tempElement.isVerified = true
                                        tempElement.isCloud = false
                                        tempElement.isFalsePositive = false
                                        squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current][index] = tempElement;
                                    }else if(element.isVerified && !element.isCloud){
                                        // if the polygon is verified and not a cloud then set it as false positive
                                        const tempElement = {...element}
                                        tempElement.isVerified = true
                                        tempElement.isCloud = false
                                        tempElement.falsePositive = true

                                        squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current][index] = tempElement;
                                    } 
                                }
                            }
                        })

                    }else{
                        isFalsePositive = true
                    }
                }else if(clickedPolygon.isCloud && !clickedPolygon.isVerified && clickedPolygon.falsePositive && !_isCloud){
                    isFalsePositive = true
                }

                affectedIndexes.forEach((key) => {
                    
                    try{                            
                        tempArray.push({
                            maskKey: key,
                            isCloud: _isCloud,
                            isVerified: true,
                            falsePositive: isFalsePositive
                        })
                    }catch(err){
                        console.error(err)
                    }
                })
                
                squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = tempArray
                                
                reRenderPolygons(true, true)



                
            }

        }catch(err){
            console.error(err)
        }

    }

    function calculateBoxFromKey(x, y, _latLngs){
        const north = _latLngs['lats'][y]
        const south = _latLngs['lats'][y + settings.ANALYTICS_PIXELS_PER_SQUARE]
        const west = _latLngs['lngs'][x]
        const east = _latLngs['lngs'][x + settings.ANALYTICS_PIXELS_PER_SQUARE]

        return {
            north: north,
            south: south,
            east: east,
            west: west
        }
    }



    function polygonUpdateCloudMaskPolygon(_isCloud){
        try{
            updatePolygonToolMask()

            // calculate bounds from the polygon
            const tempPolygon = imageryReviewMaskObjectRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].polygon

            // if(tempPolygon.length < 3){
            //     return
            // }

            tempPolygon.push(tempPolygon[0])
            const polygon = turf.polygon([tempPolygon.map((point) => { return [point.lng, point.lat] })])
            const turfBounds = turf.bbox(polygon)
            const polygonBounds = {
                north: turfBounds[3],
                south: turfBounds[1],
                east: turfBounds[2],
                west: turfBounds[0],
            }
            
            const metadataLatLngs = squareJSONMetaDataRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].squareLatLngs

            const lngs = Object.entries(metadataLatLngs.lngs)
            const lats = Object.entries(metadataLatLngs.lats)

            const indexBounds = {
                north: {
                    index: 0,
                    value: lats[0][1]
                },
                south: {
                    index: lats.length - 1,
                    value: lats[lats.length - 1][1]
                },
                east: {
                    index: lats.length - 1,
                    value: lngs[lngs.length - 1][1]
                },
                west: {
                    index: 0,
                    value: lngs[0][1]
                }
            }

            

            lngs.forEach((elem, lngIndex) => {                
                const lng = elem[1]
                // if the value is higher than the indexBounds and lower than the polygonBounds.west then update the indexbounds
                if(lng < polygonBounds.west && lng > indexBounds.west.value){
                    indexBounds.west.index = lngIndex
                    indexBounds.west.value = lng
                }
                // if the value is lower than the indexBounds and higher than the polygonBounds.east then update the indexbounds
                if(lng > polygonBounds.east && lng < indexBounds.east.value){
                    indexBounds.east.index = lngIndex
                    indexBounds.east.value = lng
                }                
            })

            lats.forEach((elem, latIndex) => {
                const lat = elem[1]
                // if the value is higher than the indexBounds and lower than the polygonBounds.south then update the indexbounds
                if(lat < polygonBounds.south && lat > indexBounds.south.value){
                    indexBounds.south.index = latIndex
                    indexBounds.south.value = lat
                }
                // if the value is lower than the indexBounds and higher than the polygonBounds.north then update the indexbounds
                if(lat > polygonBounds.north && lat < indexBounds.north.value){
                    indexBounds.north.index = latIndex
                    indexBounds.north.value = lat
                }
            })

            const northIndex = indexBounds.north.index 
            const southIndex = indexBounds.south.index + 1
            const eastIndex = indexBounds.east.index + 1
            const westIndex = indexBounds.west.index 

            const lngKeys = lngs.slice(westIndex > 0 ? westIndex:0, eastIndex < lngs.length ? eastIndex:lngs.length-1).map((elem) => elem[0])
            const latKeys = lats.slice(northIndex > 0 ? northIndex:0, southIndex < lats.length ? southIndex:lats.length-1).map((elem) => elem[0])

            const intersectingPolygons = []
            lngKeys.forEach((lngKey) => {
                latKeys.forEach((latKey) => {
                    const elementBox = calculateBoxFromKey(parseInt(lngKey), parseInt(latKey), metadataLatLngs)
                    const elementPolygon = turf.polygon([[
                        [elementBox.west, elementBox.north],
                        [elementBox.east, elementBox.north],
                        [elementBox.east, elementBox.south],
                        [elementBox.west, elementBox.south],
                        [elementBox.west, elementBox.north]
                    ]])
                    // check if it intersects with the polygon
                    if(turf.booleanIntersects(elementPolygon, polygon)){
                        intersectingPolygons.push({
                            lngKey: lngKey,
                            latKey: latKey
                        })
                    }
                })
            })
                    
            const tempSquareCLoudMaskTouchedSquares = intersectingPolygons.map(e => {
                const maskKey = `${e.latKey}-${e.lngKey}`
                return {
                    maskKey: maskKey,
                    isCloud: _isCloud,
                    isVerified: true
                }
            })

            squareCloudMaskTouchedSquaresRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = tempSquareCLoudMaskTouchedSquares
                    
            // resetPolygonToolMask()
            
            reRenderPolygons(true, true)

        }catch(err){
            console.error(err)
            createAlert('error', 'Error updating cloud mask polygon')
        }
    }

    function saveMask(_publish){


        
        var imageId = ""
        if(squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].json.rawCloudMask.Official != null){
            imageId = squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].json.rawCloudMask.Official.imageId
        }else if(squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].json.rawCloudMask.Working != null){
            imageId = squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].json.rawCloudMask.Working.imageId
        }else if(squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].json.rawCloudMask.Default != null){
            imageId = squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].json.rawCloudMask.Default.imageId
        }

        
        var workingFileName = `${currentSquareRef.current}-${currentSquareDateIdRef.current}-${imageId}-Working.json`


        const tempMetaData = squareJSONMetaDataRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current]
        const tempMask = [] 

        // foreach row in the in height and width
        for(var y = 0; y < tempMetaData.height/settings.ANALYTICS_PIXELS_PER_SQUARE; y++){
            tempMask.push([])
            for(var x = 0; x < tempMetaData.width/settings.ANALYTICS_PIXELS_PER_SQUARE; x++){
                tempMask[y].push(0)
            }
        }

        // foreach polygon in the squareCloudMaskPolygonsRef
        squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current].forEach((polygon) => {
            //foreach key in keys
            Object.keys(polygon.keys).forEach((key) => {
                try{
                    const keySplit = key.split('-')
                    const y = parseInt(parseInt(keySplit[0])/settings.ANALYTICS_PIXELS_PER_SQUARE)
                    const x = parseInt(parseInt(keySplit[1])/settings.ANALYTICS_PIXELS_PER_SQUARE)

                    if(polygon.isVerified){
                        if(polygon.isCloud){
                            tempMask[y][x] = 3
                        }else{
                            if(polygon.falsePositive){
                                tempMask[y][x] = 4
                            }else{
                                tempMask[y][x] = 2
                            }
                        }
                    }else{
                        if(polygon.isCloud){
                            tempMask[y][x] = 1
                        }
                    }
                }catch(err){
                    console.error(err)
                }
            })
        })

        const dataObject = {
            "mask": tempMask,
            "metadata": tempMetaData
        }


        const workingCloudMaskStorageRef = ref(storage, `AdminData/${currentSquareRef.current}/Imagery/PNG/UnprocessedPNG/SingleImage/RawImage/CloudMask/${workingFileName}`)
        const blob = new Blob([JSON.stringify(dataObject)], {type: 'application/json'});

        //upload the training data to storage as a json file
        uploadBytes(workingCloudMaskStorageRef, blob)
        .then((snapshot) => {
            if(_publish){
                const officialFileName = `${currentSquareRef.current}-${currentSquareDateIdRef.current}-${imageId}-Official.json`
                const officialCloudMaskStorageRef = ref(storage, `AdminData/${currentSquareRef.current}/Imagery/PNG/UnprocessedPNG/SingleImage/RawImage/CloudMask/${officialFileName}`)
                //upload the training data to storage as a json file
                uploadBytes(officialCloudMaskStorageRef, blob)
                .then((snapshot) => {
                    createAlert('success', "Changes Published")
                    // update the cached official 
                    squareJSONCloudMaskRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current]["official"] = dataObject.mask

                    
                    const dateObj = squareDataObjRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current];
                    // check if all the elements are loaded
                    const allLoaded = Object.values(dateObj).map((element) => {
                        return element?.json?.rawCloudMask?.Official != null
                    }).every((element) => element === true)
                    
                    squareDataObjRef.current[currentSquareRef.current][dateObj.dateId][dateObj.imageId].json.rawCloudMask.Official = dataObject


                    renderDateSliderStyle()
                })
                .catch((error) => {
                    console.error(error)
                    createAlert('error', error.message)
                })
                
            }else{
                createAlert('success', "Changes Saved")
            }              
        })
        .catch((error) => {
            console.error(error)
            createAlert('error', error.message)
        });

    }

    function moveThroughLocationHistory(_direction){
        try{
            if(cloudMaskLocationHistoryRef.current.length > 0){
                if(_direction == "back"){
                    if(cloudMaskLocationHistoryIndexRef.current > 0){
                        cloudMaskLocationHistoryIndexRef.current--
                    }
                }else if(_direction == "forward"){
                    if(cloudMaskLocationHistoryIndexRef.current < cloudMaskLocationHistoryRef.current.length - 1){
                        cloudMaskLocationHistoryIndexRef.current++
                    }
                }
                const bounds = cloudMaskLocationHistoryRef.current[cloudMaskLocationHistoryIndexRef.current].bounds
                panToBounds(bounds, false)
            }
        }catch(err){
            console.error(err)
        }
    }

    function moveThroughChangeHistory(_direction){
        try{
            if(cloudMaskChangeHistoryRef.current.length > 0){
                if(_direction == "back"){
                    if(cloudMaskChangeHistoryIndexRef.current > 0){
                        cloudMaskChangeHistoryIndexRef.current--
                    }
                }else if(_direction == "forward"){
                    if(cloudMaskChangeHistoryIndexRef.current < cloudMaskChangeHistoryRef.current.length - 1){
                        cloudMaskChangeHistoryIndexRef.current++
                    }
                }

                
                const tempPolygons = cloudMaskChangeHistoryRef.current[cloudMaskChangeHistoryIndexRef.current]
                if(tempPolygons == null){
                    cloudMaskChangeHistoryIndexRef.current = cloudMaskChangeHistoryRef.current.length - 1
                    return
                }

                squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = tempPolygons
                setCurrentSquareCloudMaskPolygons(tempPolygons)
                
            }
        }catch(err){
            console.error(err)
        }
    }

    function selectNextUnverifiedCloudMask(){

        try{

            const sortedPolygons = squareCloudMaskPolygonsRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current]
            .filter((element) => {
                return element.isVerified == false
            })
            .sort((a, b) => {
                return b.polygonArea - a.polygonArea
            })
            const firstPolygon = sortedPolygons[0]
            
            panToBounds(firstPolygon.bounds, false)

            // check if the firstPolygon.polygonKey is the same as the last ellement in cloudMaskHistoryRef
            if(cloudMaskLocationHistoryRef.current.length > 0){
                if(cloudMaskLocationHistoryRef.current[cloudMaskLocationHistoryRef.current.length - 1].polygonKey == firstPolygon.polygonKey){
                    // if it is then remove the last element
                    cloudMaskLocationHistoryRef.current.pop()
                }
            }
            // add the firstPolygon to the cloudMaskHistoryRef
            cloudMaskLocationHistoryRef.current.push({
                polygonKey: firstPolygon.polygonKey,
                bounds: firstPolygon.bounds
            })  

            cloudMaskLocationHistoryIndexRef.current = cloudMaskLocationHistoryRef.current.length - 1


            
        }catch(err){
            console.error(err)
        }

    }

    function changeSelectedToolType(_toolType){

        resetPolygonToolMask()

        setCurrentSquareSelectedToolType(_toolType)
    }
    

    if(!isLoaded){
        return  <>
                <div className="adminImageryReviewContainerDiv">
                </div>
                </>
    }

    var currentSquareDateHasCloudMask = false

    var allCloudMasksLoaded = currentSquareCloudMaskPolygons.filter(e => !e.isVerified).length == 0 && currentSquareCloudMaskPolygons.length > 0    

    try{
        currentSquareDateHasCloudMask = currentSquareImageryObj?.json?.rawCloudMask != null && currentSquareImageryObj?.json?.rawCloudMask != undefined && currentSquareCloudMaskPolygons.length > 0 
    }catch(err){}


    const polygonToolActive = currentSquareSelectedToolType == "polygonTool"
    const paintBucketToolActive = currentSquareSelectedToolType == "paintBucket"
    const spotToolActive = currentSquareSelectedToolType == "spotTool"
    
    return (
        <div className="adminImageryReviewContainerDiv">
            <GoogleMap           
                zoom={mapZoom} 
                center={location}             
                mapContainerClassName="adminImageryReviewBody"
                options={{
                styles: googleMapsStyle,
                disableDoubleClickZoom: true,

                }}
                onLoad={mapHandleOnLoad}
                onBoundsChanged={() => mapHandleBoundsChanged()}
                onCenterChanged={() => { handleCenterChanged(mapRef.current) }}
                mapTypeId={mapTypeId}
                onClick={(e)=> { 
                    selectSquare(null)
                }}
                onDblClick={(e)=> {                                       
                
                }}>             
                {
                    currentSquarePolygonToolMask != null ?
                        <Polygon
                            key={`currentSquarePolygonToolMask-${viewportReRendererId}`}                            
                            editable={true}
                            draggable={true}
                            paths={currentSquarePolygonToolMask.polygon}   
                            options={{
                                ...settings.rectangleOptions,
                                fillColor: theme.palette.primary.main,
                                strokeColor: theme.palette.secondary.main,
                                strokeWeight: 3,
                                zIndex: 3001,
                                fillOpacity: .5,
                            }}                            
                            onDragEnd={(e) => {
                                updatePolygonToolMask()
                            }}
                            onRightClick={(e) => {
                                polygonUpdateCloudMaskPolygon(false)
                            }}
                            onClick={(e) => {
                                polygonUpdateCloudMaskPolygon(true)
                            }}
                            onLoad={(polygon) => {
                                if(currentSquarePolygonToolMaskRef.current[currentSquareRef.current] == null){
                                    currentSquarePolygonToolMaskRef.current[currentSquareRef.current] = {}
                                }
                                if(currentSquarePolygonToolMaskRef.current[currentSquareRef.current][currentSquareDateIdRef.current] == null){
                                    currentSquarePolygonToolMaskRef.current[currentSquareRef.current][currentSquareDateIdRef.current] = {}
                                }
                                if(currentSquarePolygonToolMaskRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] == null){
                                    currentSquarePolygonToolMaskRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = {}
                                }
                                
                                currentSquarePolygonToolMaskRef.current[currentSquareRef.current][currentSquareDateIdRef.current][currentSquareDateIdSelectedImageRef.current] = polygon
                            }}
                            />:null
                }   
                {
                    visibleSquaresArray.map(key => {
                    const square = squaresRef.current[key]

                    if(currentSquare != key && currentSquare != null){
                        return null
                    }
                        
                    return square ? (
                        <Polygon
                            key={`${key}-square`}
                            editable={false}
                            draggable={false}
                            paths={square.polygon}   
                            onClick={(event) => {

                                if(currentSquare != key){
                                    selectSquare(key)
                                }

                                if(polygonToolActive){
                                    addCoordinatesToPolygonToolMask(event.latLng.lng(), event.latLng.lat())
                                }else if(spotToolActive || paintBucketToolActive){
                                    updateCoordinateSpotSquareCloudMask(event.latLng.lng(), event.latLng.lat(), true)
                                }
                            }}
                            onRightClick={(event) => {
                                if(polygonToolActive){
                                    resetPolygonToolMask()
                                }else if(spotToolActive || paintBucketToolActive){
                                    updateCoordinateSpotSquareCloudMask(event.latLng.lng(), event.latLng.lat(), false)
                                }
                            }}
                            options={{
                                ...settings.rectangleOptions,
                                fillColor: theme.palette.primary.main,
                                strokeColor: theme.palette.secondary.main,
                                strokeWeight: 3,
                                zIndex: 300,
                                fillOpacity: 0,
                            }}/>
                    ):null

                    })
                }
                {
                    debugginsPolygon != null ?
                        <Polygon
                                    key={`debuggingPolygon`}                                
                                    editable={false}
                                    draggable={false}
                                    paths={debugginsPolygon}                                   
                                    options={{
                                        ...settings.rectangleOptions,
                                    }}/>:null
                }
                {
                currentSquareVisiblePngs ? currentSquareVisiblePngs.map((pngObj) => {
                  try{
                    
                    if(currentSquareObject == null){
                      return null
                    }

                    const rawRGBUrl = pngObj?.imagery?.rawRGB

                    var displayURL = ""
                    if(rawRGBUrl){
                        displayURL = rawRGBUrl
                    }

                    return <GroundOverlay
                              key={`currentSquareVisiblePngs-${pngObj.dateId}-${pngObj.imageId}-${viewportReRendererId}`}
                              url={displayURL}
                              bounds={{
                                north: currentSquareObject.bounds.north,
                                south: currentSquareObject.bounds.south,
                                east: currentSquareObject.bounds.east,
                                west: currentSquareObject.bounds.west
                              }}
                              opacity={pngObj.endDate.getTime() == dateSliderValue && pngObj.imageId == currentSquareDateIdImageSelectedOption ? 1:0}
                              options={{
                                clickable: false,
                                zIndex: 200,                                
                              }}
                            //   onLoad={() => {
                            //     updateDateSliderMarksLoadState(pngObj.squareId, pngObj.endDateId)

                            //   }}
                            />
                  }catch(err){

                    console.error(err)
                  }
                }):null
              }
              {
                currentSquareBasemapImageryComparisonUrl != null && (currentSquareShowGeneralBasemapDiff)? 
                    <GroundOverlay
                        key={`basemapDifferences-${viewportReRendererId}`}
                        url={currentSquareBasemapImageryComparisonUrl}
                        bounds={{
                            north: currentSquareObject.bounds.north,
                            south: currentSquareObject.bounds.south,
                            east: currentSquareObject.bounds.east,
                            west: currentSquareObject.bounds.west
                        }}
                        opacity={1}
                        options={{
                            clickable: false,
                            zIndex: 201,                                
                        }}
                        />
                    :null
              }
              {
                currentSquareObject?.bounds != null ? <GroundOverlay
                    key={`currentSquareGeneralSquareBasemapUrl-${currentSquare}-${viewportReRendererId}`}
                    url={currentSquareGeneralSquareBasemapUrl ?? ""}
                    bounds={{
                        north: currentSquareObject.bounds.north,
                        south: currentSquareObject.bounds.south,
                        east: currentSquareObject.bounds.east,
                        west: currentSquareObject.bounds.west
                    }}
                    opacity={currentSquareShowGeneralBasemap ? 1:0}
                    zIndex={501}                    
                    options={{
                        clickable: false,
                        zIndex: 201,                        
                    }}/>:null
              }
              {
                currentSquareCloudMaskPolygons?.map((element) => {
                    
                    try{
                        if(currentSquareShowPolygons == false){
                            return null
                        }else{
                            const key = element.polygonKey
                            const polygon = element.polygon
                            const isCloud = element.isCloud
                            const falsePositive = element.falsePositive
                            const isVerified = element.isVerified
    
                            var polygonStyle = {
                                zIndex: 305,
                            }
    
                            if(isVerified){
                                polygonStyle = {
                                    ...polygonStyle,
                                    strokeColor: theme.palette.secondary.main,
                                    fillOpacity: .8,
                                    zIndex: 301,
                                }
                            }else{
                                polygonStyle = {
                                    ...polygonStyle,
                                    strokeColor: theme.palette.error.main,
                                    fillOpacity: 0,
                                    zIndex: 310,
                                }
                            }
    
                            if(isCloud){
                                polygonStyle = {
                                    ...polygonStyle,
                                    fillColor: theme.palette.white.main,
                                    zIndex: 303,
                                }
                            }else{
                                polygonStyle = {
                                    ...polygonStyle,
                                    fillColor: theme.palette.primary.main,
                                    zIndex: 304,
                                }
                            }

                            if(falsePositive){
                                polygonStyle = {    
                                    ...polygonStyle,
                                    strokeColor: 'purple',
                                    zIndex: 306,
                                }
                            }
    
                            return <Polygon
                                    key={`${key}-mask-${viewportReRendererId}`}                                
                                    editable={false}
                                    draggable={false}
                                    paths={polygon}                                   
                                    options={{
                                        ...settings.rectangleOptions,
                                        ...polygonStyle,
                                    }}
                                    onClick={(event) => {

                                        if(polygonToolActive){
                                            addCoordinatesToPolygonToolMask(event.latLng.lng(), event.latLng.lat())
                                        }else if(spotToolActive){
                                            updateCoordinateSpotSquareCloudMask(event.latLng.lng(), event.latLng.lat(), true)
                                        }else if(paintBucketToolActive){
                                            paintBucketUpdateCloudMaskPolygon(key, true)
                                        }
                                    }}
                                    onRightClick={(event) => {
                                        if(spotToolActive){
                                            updateCoordinateSpotSquareCloudMask(event.latLng.lng(), event.latLng.lat(), false)
                                        }else if(paintBucketToolActive){
                                            paintBucketUpdateCloudMaskPolygon(key, false)
                                        }
                                    }}/>
                            
                        }

                        
                        
                    }catch(err){
                        console.error(err)
                    }
                })
              }
            </GoogleMap>
            {/* Overlay Elements */} 
            <div className='adminImageryReviewLeftAlignedActions' style={{display: currentSquare != null ? 'flex':'none'}}>                           
                <Paper className='adminImageryReviewSelectAnalyticCard' key={`${refreshKey}-selectAnalyticCard`}>
                    <div className='adminImageryReviewSelectAnalyticCardSquareInfo' >
                        <DataDisplay 
                        icon={SelectAll} 
                        title={currentSquare} 
                        data={currentSquareObject?.id} 
                        loading={false}/>
                        
                        <DataDisplay icon={Event} title={"Date"} data={
                            dateSliderValue != null ? dateToLocaleUTCDateString(new Date(currentSquareDateId), 'en-us', {year: 'numeric', month: 'long', day: 'numeric'}):''
                        
                        } loading={false}/>
                                  
                        <div style={{display: currentSquareImageryObj?.imagery?.rawRGB ? 'flex':'none', flexDirection: 'column'}}>
                            <h5 style={{marginTop: '0px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Brightness ({imageryBrightness.toFixed(1)})</h5>
                            <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                                <IconButton 
                                onClick={() => {
                                    updateImageryBrightness(imageryBrightness - .5)
                                }}>
                                <BrightnessLow color='primary' />
                                </IconButton>
                                <Slider  
                                    aria-label="Brightness" 
                                    value={imageryBrightness} 
                                    min={0}
                                    max={100}
                                    step={.5}
                                    marks={false}
                                    color='secondary'
                                    onChange={(event) => {
                                        
                                        updateImageryBrightness(event.target.value)
                                    }} />
                                <IconButton 
                                onClick={() => {
                                    updateImageryBrightness(imageryBrightness + .5)
                                }}>
                                <BrightnessHigh color='primary' />
                                </IconButton>
                            </Stack>
                        </div>      
                        <div style={{display: 'flex', flexDirection: 'column', marginBottom: '10px'}}>
                            <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Cloud Threshold ({cloudMaskThreshold.toFixed(1)})</h5>
                            <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                                <IconButton 
                                onClick={() => {
                                    updateCloudMaskThreshold(cloudMaskThreshold - 1)
                                }}>
                                    <FilterDrama color='primary' />
                                </IconButton>
                                <Slider  
                                    aria-label="ML Confidence" 
                                    value={cloudMaskThreshold} 
                                    min={-20}
                                    max={20}
                                    step={5}
                                    marks={false}
                                    color='secondary'
                                    onChange={(event) => {
                                        
                                        updateCloudMaskThreshold(event.target.value)
                                    }} />
                                <IconButton 
                                onClick={() => {
                                    updateCloudMaskThreshold(cloudMaskThreshold + 1)
                                }}>
                                    <Cloud color='primary' />
                                </IconButton>
                            </Stack>
                        </div>      
                        <DataDisplay icon={AccountTree} title={`Data Processing - ${currentSquareDateIdImageSelectOptions.length}`} data={
                            <div style={{marginTop: '10px', width: '100%'}}>
                                <Select 
                                    variant='outlined'
                                    value={currentSquareDateIdImageSelectedOption}
                                    onChange={(e) => {

                                        currentSquareDateIdSelectedImageRef.current = e.target.value
                                        setCurrentSquareDateIdImageSelectedOption(e.target.value)

                                        updateImageryLoadState()

                                        reRenderPolygons(true, true)
                                        
                                    }}                              
                                    size='small'
                                    style={{
                                        width: '175px',
                                    }}>                                    
                                    {
                                        currentSquareDateIdImageSelectOptions.map((element) => {
                                            

                                            return  <MenuItem key={element.imageId} value={element.imageId}>
                                                        
                                                        <Badge variant={element?.imagery?.rawRGB != undefined ? 'dot':'standard'} color="secondary" style={{marginRight: '15px'}}>
                                                            <PhotoLibrary color="primary" />
                                                        </Badge>
                                                        <Badge variant={element?.json?.rawCloudMask != undefined ? 'dot':'standard'} color="secondary" style={{marginRight: '15px'}}>
                                                            <DataObject color="primary" />
                                                        </Badge>
                                                        <Badge variant={element?.imagery?.cloudMasked != undefined ? 'dot':'standard'} color="secondary" style={{marginRight: '15px'}}>
                                                            <LibraryAddCheck color="primary" />
                                                        </Badge>
                                                        {element?.imageId?.split("_")?.[1]?.split("T")?.[1]??""}
                                                    </MenuItem>
                                        })
                                    }

                                </Select>
                            </div>
                        
                        } loading={false}/>
                        <Button 
                            variant='contained'
                            color="secondary"
                            startIcon={<Cloud />}
                            onClick={() => {
                                selectImageForDataProcessing()
                            }}
                            disabled={currentSquareImageryObj?.json?.rawCloudMask == null}
                            style={{display: currentSquareDateHasCloudMask ? 'none':'flex',}}>
                            Mask Clouds
                        </Button>
                        <div style={{display: currentSquareDateHasCloudMask ? 'flex':'none', flexDirection: 'column'}}>
                            <Divider style={{marginBottom: '10px'}} />

                            <DataDisplay icon={Cloud} title={"Cloud Masks"} data={`${currentSquareCloudMaskPolygons.filter(e => !e.isVerified).length} of ${currentSquareCloudMaskPolygons.length}`} loading={false}/>
                            
                            
                            <h5 style={{marginTop: '-10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Tools</h5>
                            <div className='adminMapSelectAnalyticImagePickerArea'> 
                                
                                <IconButton
                                    onClick={() => {
                                    changeSelectedToolType("paintBucket")
                                    }}
                                    style={{backgroundColor: currentSquareSelectedToolType == "paintBucket" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                                    <FormatColorFill style={{color: theme.palette.secondary.contrastText}}/>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                    changeSelectedToolType("spotTool")
                                    }}
                                    style={{backgroundColor: currentSquareSelectedToolType == "spotTool" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                                    <ModeStandby style={{color: theme.palette.secondary.contrastText}}/>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                    changeSelectedToolType("polygonTool")
                                    }}
                                    style={{backgroundColor: currentSquareSelectedToolType == "polygonTool" ? theme.palette.secondary.main:'', marginRight: '10px'}}>
                                    <FormatShapes style={{color: theme.palette.secondary.contrastText}}/>
                                </IconButton>
                                                          
                            </div>

                            {
                                allCloudMasksLoaded ?                                     
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <Button
                                            variant='contained'
                                            color="primary"
                                            onClick={() => {
                                                saveMask(false)
                                            }}
                                            startIcon={<Save />}
                                            style={{flex: 1, marginRight: '10px'}}
                                            size='small'>                                            
                                            Save
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color="secondary"
                                            onClick={() => {
                                                saveMask(true)
                                            }}
                                            startIcon={<Upload />}
                                            size='small'>
                                            Publish
                                        </Button>
                                    </div>
                                    :
                                    <Button
                                        variant='contained'
                                        color="secondary"
                                        onClick={() => {
                                            saveMask(false)
                                        }}
                                        startIcon={<Save />}
                                        style={{flex: 1}}
                                        size='small'>
                                        
                                        Save
                                    </Button>
                                    
                            }
                                                     
                        </div>

                        
                    </div>                 
                       
                </Paper>
            </div>
            <div className='adminImageryReviewContainerSliderDiv' style={{height: currentSquare != null ? '70px':'0px', opacity: currentSquare != null ? 1:0}} data-disable-interaction="true">       
            {
                ![currentSquareImageryObj, dateSliderValue, dateSliderMarks?.marks, dateSliderMinDate, dateSliderMaxDate].includes(undefined) ? 
                    <Slider
                        step={null}
                        value={dateSliderValue}
                        onChange={(e) => handleDateSliderChange(e.target.value)}
                        marks={dateSliderMarks?.marks}
                        min={dateSliderMinDate}
                        max={dateSliderMaxDate}
                        color="secondary"                     
                        disabled={Object.keys(currentSquareImageryObj).length == 0 || currentSquare == null}     
                        className='adminImageryReviewDateSlider'
                        sx={dateSliderMarksLoadStateStyle}
                        onKeyDown={(e) => {
                            // e.preventDefault();
                            
                        }}
                        />:null
            }
            
            </div>
            
        </div>
    )

}


export default AdminImageryReview