import settings from '../../settings.json';

export function calculateNeighbors(_key, _type){
    const _x = parseInt(_key.split('-')[0])
    const _y = parseInt(_key.split('-')[1])
    const _numberOfPixels = settings.ANALYTICS_PIXELS_PER_SQUARE
    if(_type == "square"){

      return [
        `${_x-_numberOfPixels}-${_y-_numberOfPixels}`,
        `${_x}-${_y-_numberOfPixels}`,
        `${_x+_numberOfPixels}-${_y-_numberOfPixels}`,
        `${_x-_numberOfPixels}-${_y}`,
        `${_x+_numberOfPixels}-${_y}`,
        `${_x-_numberOfPixels}-${_y+_numberOfPixels}`,
        `${_x}-${_y+_numberOfPixels}`,
        `${_x+_numberOfPixels}-${_y+_numberOfPixels}`,
      ]
    }else if(_type == "diamond"){
      return [
        `${_x}-${_y-_numberOfPixels}`,
        `${_x-_numberOfPixels}-${_y}`,
        `${_x+_numberOfPixels}-${_y}`,
        `${_x}-${_y+_numberOfPixels}`,
        ]
    }else{
      return []
    }
  }

  