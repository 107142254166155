import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import firebase from 'firebase/compat/app'
import { useTheme } from '@mui/material/styles';
import { useParams, useNavigate, json } from 'react-router-dom';
import { getStorage, ref, listAll, getDownloadURL, list } from "firebase/storage";
import { Data, GoogleMap, GroundOverlay, Polygon, Rectangle, StreetViewPanorama, useLoadScript } from '@react-google-maps/api';
import './MapView.css';
import googleMapsStyle from '../../assets/googleMapsStyle.json'

import { boundsToPolygon } from '../../functions/boundsToPolygon';
import { polygonsToOutline } from '../../functions/polygonsToOutline';
import { calculatePolygonsIntersect } from '../../functions/calculatePolygonsIntersect';
import { calculateProcessingGridSquareBounds } from '../../functions/calculateProcessingGridSquareBounds'
import { dateIdToAnalyticsRequestId } from '../../functions/dateIdToAnalyticsRequestId';
import { dateIdToAnalyticsRequestIdShorthand } from '../../functions/dateIdToAnalyticsRequestIdShorthand';
import { dateIdToUTCDateObject } from '../../functions/dateIdToUTCDateObject';  
import { calculateRelativeDateSliderMarkValue } from '../../functions/calculateRelativeDateSliderMarkValue';
import { calculateDateSliderMarks } from '../../functions/calculateDateSliderMarks';
import { calculateDateMarkStyles } from '../../functions/calculateDateMarkStyles';
import { calculatePolygonBounds } from '../../functions/calculatePolygonBounds';
import { createSquare } from '../../functions/createSquare';
import { lngLatToSquareId } from '../../functions/lngLatToSquareId';
import { polygonToBounds } from '../../functions/polygonToBounds';
import { squareIdToLatLng } from '../../functions/squareIdToLatLng';

import AnalyticRendering from '../../components/AnalyticRendering/AnalyticRendering'
import { availableAnalytics } from '../../components/AnalyticRendering/AnalyticRendering'

import { Button, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Paper, Select, Skeleton, Stack,  Slider, Switch, styled, Icon } from '@mui/material';
import { ArrowCircleLeft, BrightnessHigh, BrightnessLow, ControlCamera, Visibility, Image, InvertColorsOff, InvertColors, CalendarMonth, DateRange, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, Key, Satellite, Settings, Clear, Insights, Timeline, Tune, CropSquareOutlined, Square, Grid3x3, Grid4x4, Fullscreen, FullscreenExit } from '@mui/icons-material';
import { formatDateAsId } from '../../functions/formatDateAsId';
import { dateToLocaleUTCDateString } from '../../functions/dateToLocaleUTCDateString';
import { square } from '@turf/turf';


function MapView(props) {

  const orgObj = props.organizationObj
  const theme = useTheme();
  const demoMode = props.demoMode;

  const user = props.user

  const AOIDISTANCE = .25

  const orgId = demoMode ? "R82HZCsXlZhEm5VSPpzu":orgObj?.selectedOrganization /*props.user.uid*/;
  const createAlert = props.createAlert
  const firestore = firebase.firestore();
  const storage = getStorage();
  const navigate = useNavigate();

  const {isLoaded} = useLoadScript({googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"})
  const {lotId, dateValue, analyticId} = useParams();

  const mapRef = useRef(null);
  const pegmanListerTimeoutCountRef = useRef(0)
  const mapPanoramaIsDraggingRef = useRef(false)
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [mapIsStreetViewVisible, setMapIsStreetViewVisible] = useState(false)
  const [mapPanoramaIsDragging, setMapPanoramaIsDragging] = useState(false);
  const [zoom, setZoom] = useState(5)
  const [location, setLocation] = useState({lat: 39.5, lng: -98.35})

  const dateSliderValueRef = useRef(null)
  const [dateSliderValue, setDateSliderValue] = useState(null)
  const [dateId, setDateId] = useState(null)

  const showAnalyticsRef = useRef(true)
  const showPNGImageryRef = useRef(true)
  const [showAnalytics, setShowAnalytics] = useState(true)
  const [showPNGImagery, setShowPNGImagery] = useState(true)


  const timeMappedDataRef = useRef({});
  const [currentLotTimeMappedData, setCurrentLotTimeMappedData] = useState({})
  const [reRenderInt, setReRenderInt] = useState(0)
  const currentDateIdRef = useRef(null)
  const [currentDateId, setCurrentDateId] = useState(null)

  const [currentAnalyticDateId, setCurrentAnalyticDateId] = useState("")
  const currentAnalyticDateIdRef = useRef("")
  
  const currentLoadedAnalyticsRef = useRef({})
  const currentLoadedAnalyticsFilteredRef = useRef({})
  const [currentLoadedAnalyticsFiltered, setCurrentLoadedAnalyticsFiltered] = useState({})

  const [currentLoadedImages, setCurrentLoadedImages] = useState([])
  const currentLoadedImagesRef = useRef([])

  const selectedLotKeyRef = useRef("");
  const selectedLotRef = useRef(null);
  const selectedAnalyticKeyRef = useRef(null);
  const selectedAnalyticRef = useRef(null);
  const organizationLotsRef = useRef({});


  const analyticStateRef = useRef(null)

  const updateAnalyticsFiltersTimeoutMiliseconds = 1000
  const updateAnalyticsFiltersTimeoutRef = useRef(null)
  const updateAnalyticsFiltersTimeoutLastCallRef = useRef(0)

  const defualtAnalyticsConfidence = 75
  const defualtAnalyticsGranules = 0
  const defualtAnalyticsSize = {
    min: 1,
    max: 100
  }

  const analyticsConfidenceRef = useRef(defualtAnalyticsConfidence)
  const analyticsGranulesRef = useRef(defualtAnalyticsGranules)
  const analyticsSizeRef = useRef(defualtAnalyticsSize)

  const [analyticsConfidence, setAnalyticsConfidence] = useState(analyticsConfidenceRef.current)
  const [analyticsGranules, setAnalyticsGranules] = useState(analyticsGranulesRef.current)
  const [analyticsSize, setAnalyticsSize] = useState(analyticsSizeRef.current)
    

  const dateSliderMarksRef = useRef([])
  const dateSliderMarksLoadStateRef = useRef({})
  const [dateSliderMarksLoadStateStyle, setDateSliderMarksLoadStateStyle] = useState({})
  const [dateSliderDisplayDate, setDateSliderDisplayDate] = useState(null)
  const [availableDateMarks, setAvailableDateMarks] = useState({})
  const availableDateMarksRef = useRef({})

  const [imagerySettingsMenuExpanded, setImagerySettingsMenuExpanded] = useState(false)
  const [analyticsSettingsMenuExpanded, setAnalyticsSettingsMenuExpanded] = useState(false)

  const [selectedLotKey, setSelectedLotKey] = useState("");
  const [selectedLot, setSelectedLot] = useState(null);
  const [selectedAnalyticKey, setSelectedAnalyticKey] = useState(null);
  const [selectedAnalytic, setSelectedAnalytic] = useState("none");
  const [organizationLots, setOrganizationLots] = useState({});

  const defualtImageryBrightness = 10
  const defualtImagerySaturation = 1

  const [imageryBrightness, setImageryBrightness] = useState(defualtImageryBrightness)
  const [imagerySaturation, setImagerySaturation] = useState(defualtImagerySaturation)

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }

  }, [])

  useEffect(() => {
    //clearState()
    init()
  }, [orgObj?.selectedOrganization])
  

  useEffect(() => { 


    const tempCurrentLotTimeMappedData = timeMappedDataRef.current[selectedLotRef.current?.id]
    const tempAvailableDateMarks = extractAllDatesFromTimeMappedData(tempCurrentLotTimeMappedData)
    const tempDateSliderDisplayDate = tempAvailableDateMarks?.marks?.find(a => a.value == dateSliderValueRef.current)?.dateElement?.displayDate ?? null    
    
    const tempDateSliderCurrentDateId = tempAvailableDateMarks?.marks?.find(a => a.value == dateSliderValueRef.current)?.dateElement?.dateId ?? null
    const tempDateSliderImageryLoadState = calculateDateSliderImageryLoadState(tempCurrentLotTimeMappedData?.["squareData"] ?? {})


    if(tempDateSliderCurrentDateId != null){
      const tempDateSliderMarksLoadStateStyle = calculateDateMarkStyles(tempAvailableDateMarks?.marks ?? [], tempDateSliderImageryLoadState, tempDateSliderCurrentDateId, theme, true)
      setDateSliderMarksLoadStateStyle(tempDateSliderMarksLoadStateStyle)

    }

    setAvailableDateMarks(tempAvailableDateMarks)
    availableDateMarksRef.current = tempAvailableDateMarks

    setDateSliderDisplayDate(tempDateSliderDisplayDate)


  }, [currentLotTimeMappedData, reRenderInt])
  
  function init() {

    getOrganizationLots()

  }

  function handleKeyDown(event){

    if(event.keyCode == 83){
      //s key
      //set the date to the first date in the dateSliderMarks
      handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarksRef.current?.marks, selectedAnalyticKeyRef.current, -12), true)
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 68){
      //d key
      //set the date to the middle date in the dateSliderMarks
      handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarksRef.current?.marks, selectedAnalyticKeyRef.current, -6), true)       
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 70){
      //f key
      //set the date to the last date in the dateSliderMarks
      handleDateSliderChange(calculateRelativeDateSliderMarkValue(availableDateMarksRef.current?.marks, selectedAnalyticKeyRef.current, 0), true)
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 37){
      //left arrow key
      //check if there is a previous date in the dateSliderMarks
      const currentIndex = availableDateMarksRef.current?.marks?.findIndex(mark => mark.value == dateSliderValueRef.current)
      if(currentIndex > 0){
        handleDateSliderChange(availableDateMarksRef.current?.marks[currentIndex - 1].value, true)
      }
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 39){
      //right arrow key
      //check if there is a next date in the dateSliderMarks
      const currentIndex = availableDateMarksRef.current?.marks?.findIndex(mark => mark.value == dateSliderValueRef.current)
      
      if(currentIndex < availableDateMarksRef.current?.marks?.length - 1){
        handleDateSliderChange(availableDateMarksRef.current?.marks[currentIndex + 1].value, true)
      }
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 38 || event.keyCode == 69){
      //up arrow key or e key
      //toggle png imagery visibility
      showPNGImageryRef.current = !showPNGImageryRef.current
      setShowPNGImagery(showPNGImageryRef.current)
    }else if(event.keyCode == 87){
      //w key
      showPNGImageryRef.current = true
      setShowPNGImagery(showPNGImageryRef.current)
      toggleGoogleMapType('roadmap')
    }else if(event.keyCode == 82){
      //r key
      showPNGImageryRef.current = false
      setShowPNGImagery(showPNGImageryRef.current)
      toggleGoogleMapType('hybrid')
    }else{
      // console.log(event.keyCode)
    }

  }

  

  function getOrganizationLots() {
    
    if((user != null && orgObj?.selectedOrganization != null) || demoMode){
      var query 
      query = firestore.collection('Organizations').doc(orgId).collection('Lots').where("metaData.active", "==", true).get();

      query.then((querySnapshot) => {
        var lots = {}
        querySnapshot.forEach((doc) => {
          try{
            const data = doc.data()
            lots[doc.id] = data
            lots[doc.id].id = doc.id
            
            const bounds = polygonToBounds(data.polygon)
            lots[doc.id].bounds = bounds

            //calculate required squares and bounds
            //based on the bounds find the squares that are required for the lot //squares are defined as .25 x .25 degree squares

            const requiredSquaresArea = {
              north: Math.ceil(bounds.north/0.25)*.25,
              south: Math.floor(bounds.south/0.25)*.25,
              east: Math.ceil(bounds.east/0.25)*.25,
              west: Math.floor(bounds.west/0.25)*.25
            }

            var requiredSquares = []
            var lotPolygon = data.polygon.map(point => [point.lng, point.lat])
            //if the first and last points are not the same, add the first point to the end of the array
            if(lotPolygon[0] != lotPolygon[lotPolygon.length - 1]){
              lotPolygon.push(lotPolygon[0])
            }

            //iterate through the bounds and find the squares that are required
            for(var x = requiredSquaresArea.west; x <= requiredSquaresArea.east; x += .25){
              for(var y = requiredSquaresArea.south; y <= requiredSquaresArea.north; y += .25){
                //create turf polygon from bounds
                const squarePolygonArray = [[x, y], [x, y + .25], [x + .25, y + .25], [x + .25, y], [x, y]]
                const intersects = calculatePolygonsIntersect(squarePolygonArray, [lotPolygon]) 
                if(intersects){                  
                  const squareId = lngLatToSquareId(x, y)
                  requiredSquares.push(squareId)
                }
              }
            }

            lots[doc.id].requiredSquares = requiredSquares
            var requiredSquaresBounds = {}
            requiredSquares.forEach(squareId => {
              requiredSquaresBounds[squareId.replaceAll(".", "")] = calculateImgSquareBounds(squareId)
            })
            lots[doc.id].requiredSquaresBounds = requiredSquaresBounds
            
          }catch(error){
            console.error("Error getting lot data: " + error)
          }

        });

        organizationLotsRef.current = lots
        setOrganizationLots(lots)

        if(lotId != null && lots[lotId] != null){
          setTimeout(() => {
            selectLot(lotId)
          }, 100)
        }else if(Object.keys(lots).length > 0 && selectedLotKey == ""){
          // // automatically select the first lot if lotId is not in the url and there is more than one lot
          // setTimeout(() => {
          //   selectLot(Object.values(lots).sort((a, b) => a?.displayName.toString().localeCompare(b.displayName?.toString()))[0].id)            
          // }, 100);
        }

      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
    }
  }

  

  function calculateImgSquareBounds(_gridId){

    const gridIdSplit = _gridId.replaceAll(".", "").split('-')
    const xVal = (parseInt(gridIdSplit[0])/1000) - 180
    const yVal = (parseInt(gridIdSplit[1])/1000) - 90

    return {
      north: yVal + .25,
      south: yVal,
      west: xVal, 
      east: xVal + .25
    }
  }

  
  

  

  function mapHandleOnLoad(map) {
    mapRef.current = map;    

    initPegmanListener()
    centerOnSelectedLot()

  };

  const onStreetViewLoad = useCallback((streetViewPanorama) => {
    // Check if the user is in Street View mode
    setMapIsStreetViewVisible(streetViewPanorama.getVisible());

    // Listen for changes to the visibility of Street View
    streetViewPanorama.addListener("visible_changed", () => {
      setMapIsStreetViewVisible(streetViewPanorama.getVisible());
      setMapPanoramaIsDragging(false)
    });
    

  }, []);

  function initPegmanListener(){

    pegmanListerTimeoutCountRef.current += 1
    // Select all elements with the class "gm-svpc"
    const elements = document.querySelectorAll('.gm-svpc');
    if(elements.length == 0 && pegmanListerTimeoutCountRef.current < 20){      
      setTimeout(() => {
        initPegmanListener()
      }, 500);      
    }else{

      // Add event listeners to each element
      elements.forEach(element => {
        element.addEventListener('mousedown', () => {
          setMapPanoramaIsDragging(true)       
        });
      });
      // Cleanup function to remove event listeners when component unmounts
      return () => {
        elements.forEach(element => {          
          element.removeEventListener('mousedown', () => {     

          });
        });
      };
    }
  }

  function extractAllDatesFromTimeMappedData(_timeMappedData){

    try{
      if(_timeMappedData == null){
        return {
          "marks": [],
          "min": 0,
          "max": 0
        }
      }


      const squareTimeMappedData = _timeMappedData["squareData"]

      if(squareTimeMappedData != null){
        
        var allDates = {}

        // for each square in the timeMappedData
        Object.values(squareTimeMappedData).forEach((squareData) => {       
          // for each date in the squareData
          Object.values(squareData["timeData"]).forEach((dateData) => {
            allDates[dateData.dateId] = dateData
          })
        })

        const dedupedDates = Object.values(allDates)

        var firstDateInEachYearObject = {}

        dedupedDates.forEach((dateElement) => {
          const year = dateElement.displayDate.getUTCFullYear()
          if(firstDateInEachYearObject[year] == null){
            firstDateInEachYearObject[year] = dateElement
          }else if(dateElement.displayDate.getTime() < firstDateInEachYearObject[year].displayDate.getTime()){
            firstDateInEachYearObject[year] = dateElement
          }
        })

        var firstDateInEachYear = Object.values(firstDateInEachYearObject).map(e => e.dateId)

        const minDate = Math.min(...dedupedDates.map(a => a.endDate.getTime()))
        const maxDate = Math.max(...dedupedDates.map(a => a.endDate.getTime()))
        
        const tempDateSliderMarks = dedupedDates.map((dateElement) => {

          // const element = Object.values(_squareData ?? {}).map(a => Object.values(a.timeData)).flat(Infinity).
          
          //if the date is january 1st, display the year
          var label = ""
          //get utc date for the first date in the year
          if(firstDateInEachYear.includes(dateElement.dateId)){

            //get UTC month and year abbreviation            
            label = dateToLocaleUTCDateString(
              dateElement.displayDate, 
              "en-US", 
              {year: 'numeric'})
          }
          

          return {
            value: dateElement.endDate.getTime(),
            label: label,    
            dateElement: {
              dateId: dateElement.dateId,
              displayDate: dateElement.displayDate,
              endDate: dateElement.endDate,
              endDateId: dateElement.endDateId,
              dateRange: dateElement.dateRange ?? "",
              
            }
               
          }
        })

        return {
          "marks": tempDateSliderMarks.sort((a, b) => a.value - b.value),
          "min": minDate,
          "max": maxDate
        }
      }

      return {
        "marks": [],
        "min": 0,
        "max": 0
      }
  
    }catch(error){
      console.error("Error extracting dates from time mapped data: " + error)
      return {
        "marks": [],
        "min": 0,
        "max": 0,

      }
    }
  }
  

  async function selectLot(_lotId){

    if(organizationLotsRef.current[_lotId] != null){
      
      selectedLotRef.current = organizationLotsRef.current[_lotId]
      setSelectedLot(organizationLotsRef.current[_lotId])
      selectedLotKeyRef.current = _lotId
      setSelectedLotKey(selectedLotKeyRef.current)

      //if demo mode
      if(demoMode){
        //update the web url route
        navigate(`/MapView/${_lotId}`)

      }else{
        //update the web url route
        navigate(`/Organization/MapView/${_lotId}`)

      }
      //check if there is a brightness setting for the lot in local storage
      var brightness = defualtImageryBrightness
      var localStorageBrightness = localStorage.getItem(`imageryBrightness-${orgId}-${_lotId}`)
      if(localStorageBrightness != null){
        brightness = parseFloat(localStorageBrightness)
      }else if(organizationLotsRef.current[_lotId]?.defaultSettings?.brightness != null){
        brightness = parseFloat(organizationLotsRef.current[_lotId]?.defaultSettings?.brightness)
      }

      var saturation = defualtImagerySaturation
      var localStorageSaturation = localStorage.getItem(`imagerySaturation-${orgId}-${_lotId}`)
      if(localStorageSaturation != null){
        saturation = parseFloat(localStorageSaturation)
      }else if(organizationLotsRef.current[_lotId]?.defaultSettings?.saturation != null){
        saturation = parseFloat(organizationLotsRef.current[_lotId]?.defaultSettings?.saturation)
      }

      updateImageryStyle(brightness, saturation)


    
    
      centerOnSelectedLot()


      // get analytics data
      await getLotAnalytics(orgId, _lotId)

      var imgPromiseArray = []
      //foreach square in requiredSquares, get the square imagery
      organizationLotsRef.current[_lotId]?.requiredSquares?.forEach(async (squareId) => {
        imgPromiseArray.push(getSquareImagery(orgId, _lotId, squareId.replaceAll(".", "")))        
      })
      await Promise.all(imgPromiseArray)

      setCurrentLotTimeMappedData(timeMappedDataRef.current[_lotId])
      setReRenderInt(Math.random())

      const availableDateMarks = extractAllDatesFromTimeMappedData(timeMappedDataRef.current[_lotId])
      if(availableDateMarks.marks.length > 0){
        // if the dateSliderValue is in the marks, handle date slider change to that date, otherwise handle it to the highest date
        if(availableDateMarks.marks.find(a => a.value == dateSliderValueRef.current) != null){
          handleDateSliderChange(dateSliderValueRef.current, true)
        }else{
          handleDateSliderChange(availableDateMarks.marks[availableDateMarks.marks.length - 1].value, true)
        }
      }

    }else{
      
      
      
      selectedLotRef.current = null
      setSelectedLot(null)
      selectedLotKeyRef.current = ""
      setSelectedLotKey("")
      setCurrentLotTimeMappedData(null)
      setReRenderInt(Math.random())
      setCurrentLoadedImages([])
      currentLoadedImagesRef.current = []

      navigate(`/MapView`)
       
    }

  }

    

  function initTimeDataObject(_squareId, _dateId, _endYear, _endMonth, _endDay){
    
    const endDate = new Date(Date.UTC(_endYear, _endMonth - 1, _endDay))

    return {
      squareId: _squareId,
      dateId: _dateId,
      endDateId: formatDateAsId(endDate),
      endDate: endDate,
      displayDate: dateIdToUTCDateObject(_dateId),          
              
    }

  }

  function getLotAnalytics(_organizationId, _lotId){
    return new Promise((resolve, reject) => {
      try{
        if(_organizationId != null && _lotId != null){

          if(timeMappedDataRef.current[_lotId] == null){
            timeMappedDataRef.current[_lotId] = {}
          }
          if(timeMappedDataRef.current[_lotId]["analyticsData"] == null){
            timeMappedDataRef.current[_lotId]["analyticsData"] = {
              "anlyticsFetched": false,
              "timeData": {}
            }
          }

          if(timeMappedDataRef.current[_lotId]["analyticsData"].anlyticsFetched != true){

            timeMappedDataRef.current[_lotId]["analyticsData"].anlyticsFetched = true
            const analyticsRef = ref(storage, `OrganizationData/${_organizationId}/LotData/${_lotId}/Analytics`)
            
            listAll(analyticsRef)
            .then((res) => {
              res.items.forEach((itemRef) => {
                try{
                  const fileNameArray = itemRef.name.split(".")[0].split("-")

                  const endYear = fileNameArray[1]
                  const endMonth = fileNameArray[2]
                  const endDay = fileNameArray[3]

                  const endDateString = `${endYear}-${endMonth}-${endDay}`

                  if(timeMappedDataRef.current[_lotId]["analyticsData"]["timeData"][endDateString] == null){                    
                    timeMappedDataRef.current[_lotId]["analyticsData"]["timeData"][endDateString] = initTimeDataObject("analytics", endDateString, endYear, endMonth, endDay)
                  }

                  if(timeMappedDataRef.current[_lotId]["analyticsData"]["timeData"][endDateString].analytics == null){
                    timeMappedDataRef.current[_lotId]["analyticsData"]["timeData"][endDateString].analytics = {
                      fileRef: itemRef,
                      dataObject: null,
                      loaded: null,
                      called: false
                    }
                  }
                  
                }catch(error){
                  console.error("Error getting lot analytics: " + error)
                }
              })
            })
            .catch((error) => {
              console.error("Error getting lot analytics: " + error)
            })
          }

          resolve()
          

        }else{
          resolve()
        }
      }catch(error){
        console.error("Error getting lot analytics: " + error)
        resolve()
      }
    })
  }


  function getSquareImagery(_organizationId, _lotId, _squareId){
    return new Promise((resolve, reject) => {
      try{

        if(_organizationId != null && _lotId != null && _squareId != null){
          if(timeMappedDataRef.current[_lotId] == null){
            timeMappedDataRef.current[_lotId] = {}
          }

          if(timeMappedDataRef.current[_lotId]["squareData"] == null){
            timeMappedDataRef.current[_lotId]["squareData"] = {}
          }

          if(timeMappedDataRef.current[_lotId]["squareData"][_squareId] == null){
            timeMappedDataRef.current[_lotId]["squareData"][_squareId] = {            
              pngImageryFetched: null,
              timeData: {}
            }
          }

          var promiseArrayIndex = []
          var promiseArray = []
          if(timeMappedDataRef.current[_lotId]["squareData"][_squareId].pngImageryFetched == null){
            timeMappedDataRef.current[_lotId]["squareData"][_squareId].pngImageryFetched = true
            
            //listen to the storage for the square imagery
            const pngRef = ref(storage, `OrganizationData/${_organizationId}/LotData/${_lotId}/SquareData/${_squareId}/Imagery/PNGs`)
            promiseArrayIndex.push("png")
            promiseArray.push(listAll(pngRef))
          }


          Promise.all(promiseArray)
          .then((res) => {
            res.forEach(async (promiseData, index) => {

              if(promiseArrayIndex[index] == "png"){
                promiseData.items.forEach((itemRef) => {            
                  try{
                    const fileName = itemRef.name.split(".")[0]
                    const dateId = fileName.split(`${_squareId}-`)[1]
                
                    // const startYear = dateId.split('-')[0]
                    // const startMonth = dateId.split('-')[1]
                    // const startDay = dateId.split('-')[2]
                    const endYear = dateId.split('-')[3]
                    const endMonth = dateId.split('-')[4]
                    const endDay = dateId.split('-')[5]
      
                    const endDateString = `${endYear}-${endMonth}-${endDay}`
      
                    if(timeMappedDataRef.current[_lotId]["squareData"][_squareId]['timeData'][endDateString] == null){                    
                      timeMappedDataRef.current[_lotId]["squareData"][_squareId]['timeData'][endDateString] = initTimeDataObject(_squareId, endDateString, endYear, endMonth, endDay)
                    }
      
                    //check if the png imagery is already in the timeMappedData object
                    if(timeMappedDataRef.current[_lotId]["squareData"][_squareId]['timeData'][endDateString].pngImagery == null){
                      timeMappedDataRef.current[_lotId]["squareData"][_squareId]['timeData'][endDateString].pngImagery = {
                        fileRef: itemRef,
                        url: null,
                        loaded: false,
                        called: false
                      }  
                    }                 
                  }catch(error){
                    console.error("Error getting square imagery: " + error)
                  } 
                }) 
              }            
            })
            resolve()
          })
          .catch((error) => {
            console.error("Error getting square imagery: " + error)
            resolve()
          })
        }else{
          resolve()
        }    
      }catch(error){
        console.error("Error getting square imagery: " + error)
        resolve()
      }
    })  
  }  

  function centerOnSelectedLot(){

    if(selectedLotRef.current?.bounds != null && mapRef.current != null){
      panToBounds(selectedLotRef.current.bounds)
    }  
  }

  function panToBounds(_bounds){
    if(mapRef.current){
      mapRef.current.fitBounds(_bounds)
    }
  }


  function toggleGoogleMapType(_type = null){

    if(_type == 'roadmap' || _type == 'hybrid'){
      setMapTypeId(_type)
    }else{      
      setMapTypeId(prev => prev === 'hybrid' ? 'roadmap' : 'hybrid');
    }
  }

  function updateImageryStyle(_brightness, _saturation){
    
    var tempBrightness = imageryBrightness
    var tempSaturation = imagerySaturation

    if(_brightness > 0 && _brightness <= 100){
      setImageryBrightness(_brightness)
      tempBrightness = _brightness
    }
    if(_saturation > 0 && _saturation <= 4){    
      setImagerySaturation(_saturation)
      tempSaturation = _saturation
    }
    document.getElementById('dynamicCSS').innerHTML = `
      img[src*="firebase"] {
        filter: brightness(${Math.sqrt(_brightness)}) saturate(${_saturation});
      }
    `;


    //store the brightness in local storage tied to the orgId and lotId
    localStorage.setItem(`imageryBrightness-${orgId}-${selectedLotKeyRef.current}`, _brightness)
    localStorage.setItem(`imagerySaturation-${orgId}-${selectedLotKeyRef.current}`, _saturation)

  }


  function updateAnalyticsFilters(_confidence, _granlues, _sizeObject){

    // make sure the confidence is between 0 and 100
    if(_confidence >= 0 && _confidence <= 100 && _confidence != analyticsConfidenceRef.current){
      setAnalyticsConfidence(_confidence)
      analyticsConfidenceRef.current = _confidence
    }

    // make sure the granules is between 0 and 4
    if(_granlues >= 0 && _granlues <= 4 && _granlues != analyticsGranulesRef.current){
      setAnalyticsGranules(_granlues)
      analyticsGranulesRef.current = _granlues
    }

    // make sure the size is between 1 and 100
    if(_sizeObject.min >= 1 && _sizeObject.max <= 1000 && JSON.stringify(_sizeObject) != JSON.stringify(analyticsSizeRef.current)){
      setAnalyticsSize(_sizeObject)
      analyticsSizeRef.current = _sizeObject
    }

    //store the brightness in local storage tied to the orgId and lotId
    localStorage.setItem(`analyticConfidence-${orgId}-${selectedLotKeyRef.current}-${currentAnalyticDateIdRef.current}`, _confidence)
    localStorage.setItem(`analyticGranules-${orgId}-${selectedLotKeyRef.current}-${currentAnalyticDateIdRef.current}`, _granlues)
    localStorage.setItem(`analyticSizeObject-${orgId}-${selectedLotKeyRef.current}-${currentAnalyticDateIdRef.current}`, JSON.stringify(_sizeObject))

    reRenderFilteredAnalytics(_confidence, _granlues, _sizeObject, false, false)
  }

  function reRenderFilteredAnalytics(_confidence, _granlues, _sizeObject, _recursive, _immediate){

    // check if the analytics filters have been changed since the last timeout to prevent over-reendering
    if(!_immediate && (updateAnalyticsFiltersTimeoutLastCallRef.current + updateAnalyticsFiltersTimeoutMiliseconds > Date.now() && _recursive == false)){

      // clear the timeout
      clearTimeout(updateAnalyticsFiltersTimeoutRef.current)

      updateAnalyticsFiltersTimeoutRef.current = setTimeout(() => {

        reRenderFilteredAnalytics(_confidence, _granlues, _sizeObject, true, false)

      }, updateAnalyticsFiltersTimeoutMiliseconds)


    }else{
      updateAnalyticsFiltersTimeoutLastCallRef.current = Date.now()
      
      var tempCurrentLoadedAnalyticsFiltered = {...currentLoadedAnalyticsRef.current}

      // perform the filtering
      tempCurrentLoadedAnalyticsFiltered["changeGroups"] = tempCurrentLoadedAnalyticsFiltered?.["changeGroups"]?.filter((changeGroup) => {
        const confidenceCheck = changeGroup?.["md"]?.["maxMl"] >= _confidence
        const granulesCheck = changeGroup?.["md"]?.["maxGran"] >= _granlues
        const sizeCheck = changeGroup?.["md"]?.["count"] >= scaleValue(_sizeObject.min) && changeGroup?.["md"]?.["count"] <= scaleValue(_sizeObject.max)

        return confidenceCheck && granulesCheck && sizeCheck
      })

      if(JSON.stringify(tempCurrentLoadedAnalyticsFiltered) != JSON.stringify(currentLoadedAnalyticsFilteredRef.current)){
        currentLoadedAnalyticsFilteredRef.current = tempCurrentLoadedAnalyticsFiltered
        setCurrentLoadedAnalyticsFiltered(currentLoadedAnalyticsFilteredRef.current)
          
      }
      
      
    }    
  }

  function scaleValue(_int){
    /**
     * Scales an input x from the range [1, 100] to [1, 100000] 
     * with slow growth initially and exponential increase after 75.
     */
    const a = 13; // Adjusts the curve steepness
    const xScaled = (_int - 1) / (100 - 1); // Normalize x to range [0,1]

    // Apply an exponential curve with controlled growth
    const y = 1 + (100000 - 1) * (Math.exp(a * xScaled) - 1) / (Math.exp(a) - 1);

    return Math.round(y);

  }

  async function selectAnalytic(_endDateId) {

    setCurrentAnalyticDateId(_endDateId)
    currentAnalyticDateIdRef.current = _endDateId
    
    // load the date imagery
    const lotTimeDataObj = timeMappedDataRef.current[selectedLotRef.current.id]


    // check and fetch analytics data
    const lotTimeDataObjAnalyticTimeData = lotTimeDataObj["analyticsData"]["timeData"]
    const matchingAnalyticObject = Object.values(lotTimeDataObjAnalyticTimeData).find(a => a.dateId == _endDateId && a?.analytics?.called == false)

    if(matchingAnalyticObject != null){

      const analyticsObjectUrl = await getDownloadURL(matchingAnalyticObject.analytics.fileRef)
      timeMappedDataRef.current[selectedLotRef.current.id]["analyticsData"]["timeData"][_endDateId].analytics.url = analyticsObjectUrl 
      timeMappedDataRef.current[selectedLotRef.current.id]["analyticsData"]["timeData"][_endDateId].analytics.called = true

      // download the analytics data 
      const analyticsData = await fetch(analyticsObjectUrl)
      const analyticsDataJson = await analyticsData.json()

      if(typeof analyticsDataJson == "object"){
        timeMappedDataRef.current[selectedLotRef.current.id]["analyticsData"]["timeData"][_endDateId].analytics.dataObject = analyticsDataJson
        timeMappedDataRef.current[selectedLotRef.current.id]["analyticsData"]["timeData"][_endDateId].analytics.loaded = true
      
      }else{
        timeMappedDataRef.current[selectedLotRef.current.id]["analyticsData"]["timeData"][_endDateId].analytics.loaded = false
      }

    }

    var tempDataObject = timeMappedDataRef.current?.[selectedLotRef.current.id]?.["analyticsData"]?.["timeData"]?.[_endDateId]?.analytics?.dataObject

    // get the current time analytic dataObject
    if(tempDataObject != null){

      //check if there is a confidence setting for the lot in local storage
      var confidence = defualtAnalyticsConfidence
      var localStorageAnaltyicsConfidence = localStorage.getItem(`analyticConfidence-${orgId}-${selectedLotRef.current.id}-${_endDateId}`)
      if(localStorageAnaltyicsConfidence != null){
        confidence = parseFloat(localStorageAnaltyicsConfidence)
      }

      //check if there is a granules setting for the lot in local storage
      var granules = defualtAnalyticsGranules
      var localStorageAnaltyicsGranules = localStorage.getItem(`analyticGranules-${orgId}-${selectedLotRef.current.id}-${_endDateId}`)
      if(localStorageAnaltyicsGranules != null){
        granules = parseFloat(localStorageAnaltyicsGranules)
      }
      //check if there is a size setting for the lot in local storage
      var sizeObject = defualtAnalyticsSize
      var localStorageAnaltyicsSizeObject = localStorage.getItem(`analyticSizeObject-${orgId}-${selectedLotRef.current.id}-${_endDateId}`)
      if(localStorageAnaltyicsSizeObject != null){
        sizeObject = JSON.parse(localStorageAnaltyicsSizeObject)
      }

      updateAnalyticsFilters(confidence, granules, sizeObject)

      tempDataObject = {...tempDataObject}

      try{
        
        tempDataObject["changeGroups"] = tempDataObject["changeGroups"].map((changeGroup) => {

          

          return {
            ...changeGroup,
            lotId: selectedLotRef.current.id.toString(),
            p: changeGroup.p.map((point) => {
              const lngVal = tempDataObject["coordinateMapping"]["indexToLng"][point["x"]]
              const latVal = tempDataObject["coordinateMapping"]["indexToLat"][point["y"]]
              
              return {
                lng: lngVal,
                lat: latVal
              }
            })
          }
        })

        if(JSON.stringify(tempDataObject) != JSON.stringify(currentLoadedAnalyticsRef.current)){
          currentLoadedAnalyticsRef.current = tempDataObject

          reRenderFilteredAnalytics(analyticsConfidenceRef.current, analyticsGranulesRef.current, analyticsSizeRef.current, false, true)
        }

      }catch(error){
        console.error("Error formatting analytics data: " + error)

        currentLoadedAnalyticsRef.current = {}
        reRenderFilteredAnalytics(analyticsConfidenceRef.current, analyticsGranulesRef.current, analyticsSizeRef.current, false, true)

      }

    }else{
      
      currentLoadedAnalyticsRef.current = {}
      reRenderFilteredAnalytics(analyticsConfidenceRef.current, analyticsGranulesRef.current, analyticsSizeRef.current, false, true)
    }
  }

  function handleDateSliderChange(_value, _renderImagery){
    try{
      
      // set the dateSliderValue
      if (_value != null) {
        dateSliderValueRef.current = _value
        setDateSliderValue(_value)

        const availableDateMarks = extractAllDatesFromTimeMappedData(timeMappedDataRef.current[selectedLotRef.current.id])

        const currentDateMark = availableDateMarks.marks.find(a => a.value == _value)
        const tempCurrentDateId = currentDateMark?.dateElement?.dateId ?? null

        currentDateIdRef.current = tempCurrentDateId
        setCurrentDateId(tempCurrentDateId)


        if (tempCurrentDateId == null) {
          // raise an error
          console.error("Error getting current date mark")
        }else{

          var promiseArrayIndex = []
          var promiseArray = []


          // load the date imagery
          const lotTimeDataObj = timeMappedDataRef.current[selectedLotRef.current.id]


          const lotTimeDataObjSquareData = lotTimeDataObj["squareData"]
          // foreach square in the object 
          Object.keys(lotTimeDataObjSquareData).forEach((squareId) => {

            // find if any of the date have the 
            const squareDateData = lotTimeDataObjSquareData[squareId].timeData
            const matchingElements = Object.values(squareDateData).filter(a => a.dateId == tempCurrentDateId && a.pngImagery?.called == false)

            // for emlement in matching elements
            matchingElements.forEach((element) => {
              promiseArrayIndex.push({
                squareId: squareId.toString(),
                dateId: tempCurrentDateId.toString(),
                lotId: selectedLotRef.current.id.toString()
              })
              const imageRef = element.pngImagery.fileRef
              promiseArray.push(getDownloadURL(imageRef))

              timeMappedDataRef.current[selectedLotRef.current.id]["squareData"][squareId].timeData[tempCurrentDateId].pngImagery.called = true
            })            
          })

          Promise.all(promiseArray)
          .then((res) => {
            res.forEach((url, index) => {
              const squareId = promiseArrayIndex[index].squareId
              const dateId = promiseArrayIndex[index].dateId
              const lotId = promiseArrayIndex[index].lotId

              timeMappedDataRef.current[lotId]["squareData"][squareId].timeData[dateId].pngImagery.url = url

            })

            

            const tempCurrentLoadedImages = Object.entries(timeMappedDataRef.current[selectedLotRef.current.id]["squareData"] ?? {}).map(([squareId, squareData]) => {
              return Object.entries(squareData.timeData ?? {})
                            .filter(([dateId, dateData]) => dateData.pngImagery?.called == true)
                            .map(([dateId, dateData]) => {
                              const squareLngLat = squareIdToLatLng(squareId)
                              const bounds = calculatePolygonBounds(createSquare(squareLngLat.lng, squareLngLat.lat, 0.25))
                              return {
                                squareId: squareId,
                                dateId: dateId,
                                pngImagery: dateData.pngImagery,
                                bounds: bounds,
                                lotId: selectedLotRef.current.id
                              }
                            })
                            
            }).flat(Infinity)

            if(JSON.stringify(tempCurrentLoadedImages) != JSON.stringify(currentLoadedImagesRef.current)){
              setCurrentLoadedImages(tempCurrentLoadedImages)
              currentLoadedImagesRef.current = tempCurrentLoadedImages
            }

            
            setCurrentLotTimeMappedData(timeMappedDataRef.current[selectedLotRef.current.id])
            setReRenderInt(Math.random())

          })
  
        }

      }else{
        setCurrentLoadedImages([])
        currentLoadedImagesRef.current = []
      }
    }catch(error){
      console.error("Error handling date slider change: " + error)
    }

  }

  function updateDateSliderMarksLoadState(_lotId, _squareId, _dateId){

    try{
      timeMappedDataRef.current[_lotId]["squareData"][_squareId]['timeData'][_dateId].pngImagery.loaded = true

      // update the current lot time mapped data
      setCurrentLotTimeMappedData(timeMappedDataRef.current[selectedLotRef.current.id])
      setReRenderInt(Math.random())

      

    }catch(error){
      console.error("Error updating date slider marks load state: " + error)
    }

  }

  function calculateDateSliderImageryLoadState(_timeMappedData){

    var loadState = {}
    // foreach square in the timeMappedData
    Object.keys(_timeMappedData ?? {}).forEach((squareId) => {
      loadState[squareId] = {}
      // foreach date in the square
      Object.keys(_timeMappedData[squareId]?.timeData ?? {}).forEach((dateId) => {
        loadState[squareId][dateId] = _timeMappedData[squareId]?.timeData[dateId]?.pngImagery?.loaded ?? false
      })
    })

    return loadState

  }

  const totalExpectedImages = 0 // Object.keys(availablePNGImagery[selectedLotKey] ?? {}).length
  const totalAvailableImages = 0 // Object.keys(availablePNGImagery[selectedLotKey] ?? {}).map((squareId) => { try{return availablePNGImagery[selectedLotKey][squareId][dateId]}catch(err){return []}}).filter(e => e != undefined).length
  

  return (
    <div className="mapViewContent">      
      <Paper 
        className='mapViewActionsDiv'
        elevation={5}>
          <Paper className='mapViewCardMapSelector' elevation={5} style={{borderRadius: '0px'}}>
          {
                mapIsStreetViewVisible ? 
                  <Button 
                    variant={"text"}
                    color={"primary"}  
                    onClick={() => {
                      setMapIsStreetViewVisible(false);
                      setMapPanoramaIsDragging(false)
                    }}
                    style={{flex: 1, height: '45px', lineHeight: '45px'}}
                    startIcon={<ArrowCircleLeft />}>
                      Exit Street View
                  </Button>
                :<>
                  <Button 
                    variant={"text"}
                    color={mapTypeId == "roadmap" ? "primary":"gray"}  
                    onClick={() => {toggleGoogleMapType("roadmap")}}
                    style={{flex: 1, height: '45px', lineHeight: '45px'}}
                    >
                      Map
                  </Button>
                  <Button 
                    variant={"text"}
                    color={mapTypeId == "hybrid" ? "primary":"gray"}  
                    onClick={() => {toggleGoogleMapType("hybrid")}}
                    style={{ flex: 1, height: '45px', lineHeight: '45px'}}
                    >
                      Satellite
                  </Button>
                </>
              }
          </Paper>

          <div className='analyticsSettingsActionsAnalyticsSelector' style={{display: !mapIsStreetViewVisible ? 'flex':'none', width: '100%', marginTop: '3px'}}>
            <FormControl 
              size="small" 
              className="" 
              key="lotForm"
              style={{flexDirection: 'row', margin: '8px', marginTop: '12px', width: '100%'}}>
              <InputLabel 
                  color="primary"
                  size="small">Select Lot</InputLabel>
                <Select              
                  value={selectedLotKey}
                  label="Select Lot"                            
                  onChange={(event) => {
                      selectedLotKeyRef.current = event.target.value;
                      setSelectedLotKey(selectedLotKeyRef.current);
                      selectLot(selectedLotKeyRef.current)
                  }}
                  color="primary"
                  className='mapViewHeaderLotPickerSelect'
                  style={{width: '100%'}}>
                  {
                      Object.values(organizationLots)
                        .filter(lotObj => lotObj != null)
                        .sort((a, b) => a?.displayName.toString().localeCompare(b.displayName?.toString()))
                        .map((lotObj) => {
                          
                          
                          return <MenuItem value={lotObj.id} key={`lot-${lotObj.id}`} >{lotObj?.displayName}</MenuItem>

                      })
                  }                      
                </Select>                
            </FormControl> 
          </div>                    
          <div className='mapViewCardShowImagerySelector' style={{display: selectedLotKey != "" && !mapIsStreetViewVisible ? 'flex':'none'}}>
            <Button 
              variant={"contained"}
              color={showPNGImagery ? "secondary":"primary"}  
              onClick={() => {
                showPNGImageryRef.current = !showPNGImageryRef.current
                setShowPNGImagery(showPNGImageryRef.current)
              }}
              style={{ flex: 1, height: '45px', lineHeight: '45px', width: '100%', borderRadius: '0px'}}
              startIcon={showPNGImagery ? <Satellite />:<Image />}
              >
                Imagery
            </Button>
            <Button
              variant={"contained"}
              color={showPNGImagery ? "white":"gray"}
              onClick={() => {
                setImagerySettingsMenuExpanded(!imagerySettingsMenuExpanded)
              }}
              disabled={!showPNGImagery}
              style={{ borderRadius: '0px'}}>
                <Settings />
            </Button>

          </div>
          
          <div className='mapViewActionsDivContent' style={{display: !mapIsStreetViewVisible && imagerySettingsMenuExpanded && showPNGImagery ? 'flex':'none'}}>
                            
              <div style={{display: showPNGImagery ? 'flex':'none', flexDirection: 'column', }}>
                  <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Brightness ({imageryBrightness.toFixed(0)})</h5>
                  <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                      <IconButton 
                        onClick={() => {
                          updateImageryStyle(imageryBrightness - .5, imagerySaturation)
                        }}>
                        <BrightnessLow color='primary' />
                      </IconButton>
                      <Slider 
                          aria-label="Brightness" 
                          value={imageryBrightness} 
                          min={0}
                          max={100}
                          step={.5}
                          marks={false}
                          color='secondary'
                          onChange={(event) => {
                              
                              updateImageryStyle(event.target.value, imagerySaturation)
                          }} />
                      <IconButton 
                        onClick={() => {
                          updateImageryStyle(imageryBrightness + .5, imagerySaturation)
                        }}>
                        <BrightnessHigh color='primary' />
                      </IconButton>
                  </Stack>
              </div>
              <div style={{display: showPNGImagery ? 'flex':'none', flexDirection: 'column'}}>
                  <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Saturation ({imagerySaturation.toFixed(1)})</h5>
                  <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                      <IconButton 
                        onClick={() => {
                          updateImageryStyle(imageryBrightness, imagerySaturation - .1)
                        }}>
                        <InvertColorsOff color='primary' />
                      </IconButton>
                      <Slider 
                          aria-label="Brightness" 
                          value={imagerySaturation} 
                          min={0}
                          max={4}
                          step={.1}
                          marks={false}
                          color='secondary'
                          onChange={(event) => {
                              
                              updateImageryStyle(imageryBrightness, event.target.value)
                          }} />
                      <IconButton 
                        onClick={() => {
                          updateImageryStyle(imageryBrightness, imagerySaturation + .1)
                        }}>
                        <InvertColors color='primary' />
                      </IconButton>
                  </Stack>
              </div>              
            </div>   

          
      </Paper>

      <Paper 
        className='analyticsSettingsActionsDiv' 
        elevation={5}
        style={{display: selectedLot != null && !mapIsStreetViewVisible ? 'flex':'none'}}>
          <Paper elevation={5} className='analyticsSettingsActionsAnalyticsSelector'>
            <FormControl 
              size="small" 
              className="" 
              key="lotForm"
              style={{flexDirection: 'row', margin: '8px', marginTop: '12px', width: '100%'}}>
              <InputLabel 
                  color="primary"
                  size="small">Select Analytics</InputLabel>
                <Select              
                  value={currentAnalyticDateId}
                  label="Detected Changes"                            
                  onChange={(event) => {                  

                      selectAnalytic(event.target.value)
                  }}
                  color="primary"
                  className='mapViewHeaderLotPickerSelect'
                  style={{width: "100%"}}>                  
                  {
                      Object.values(currentLotTimeMappedData?.analyticsData?.timeData ?? {})                    
                        .sort((a, b) => a?.dateId.toString().localeCompare(b.dateId?.toString()))
                        .map((analytic) => {
                          
                          var analyticDate = new Date(analytic?.dateId)
                          // subtract 1 month from the date
                          analyticDate.setUTCMonth(analyticDate.getUTCMonth() - 1)

                          const dateString = dateToLocaleUTCDateString(analyticDate, "en-US", {year: 'numeric', month: 'long',})      

                          return <MenuItem value={analytic?.dateId} key={`analyticSelect-${selectedLotKey}-${analytic?.dateId}`} >{dateString}</MenuItem>

                      })
                  }                      
                </Select>                
            </FormControl> 
          </Paper>
          <Divider ></Divider>
          <div className='analyticsSettingsActionsAnalyticsSelector' style={{display: currentAnalyticDateId != "" ? 'flex':'none'}}>
            <Button 
              variant={"contained"}
              color={showAnalytics ? "secondary":"primary"}  
              onClick={() => {
                showAnalyticsRef.current = !showAnalyticsRef.current
                setShowAnalytics(showAnalyticsRef.current)
              }}
              style={{ flex: 1, height: '45px', lineHeight: '45px', width: '200px', borderRadius: '0px', }}
              startIcon={showAnalytics ? <Insights />:<Timeline />}
              >
                Analytics
            </Button>
            <Button
              variant={"contained"}
              color={showAnalytics ? "white":"gray"}
              onClick={() => {
                setAnalyticsSettingsMenuExpanded(!analyticsSettingsMenuExpanded)
              }}
              disabled={currentAnalyticDateId == ""}
              style={{ borderRadius: '0px', width: '45px', minWidth: '0px', padding: '0px'}}>
                <Tune />
            </Button>

          </div>
          
          <div className='mapViewActionsDivContent' style={{display: !mapIsStreetViewVisible && analyticsSettingsMenuExpanded && showAnalytics ? 'flex':'none'}}>
                            
              <div style={{display: showAnalytics ? 'flex':'none', flexDirection: 'column', }}>
                  <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Confidence ({analyticsConfidence.toFixed(1)})</h5>
                  <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                      <IconButton 
                        onClick={() => {
                          updateAnalyticsFilters(analyticsConfidence - 1, analyticsGranules, analyticsSize)
                        }}>
                        <CropSquareOutlined color='primary' />
                      </IconButton>
                      <Slider 
                          aria-label="Confidence" 
                          value={analyticsConfidence} 
                          min={0}
                          max={100}
                          step={1}
                          marks={false}
                          color='secondary'
                          onChange={(event) => {
                              
                            updateAnalyticsFilters(event.target.value, analyticsGranules, analyticsSize)
                          }} />
                      <IconButton 
                        onClick={() => {
                          updateAnalyticsFilters(analyticsConfidence + 1, analyticsGranules, analyticsSize)
                        }}>
                        <Square color='primary' />
                      </IconButton>
                  </Stack>
              </div>
              <div style={{display: showAnalytics ? 'flex':'none', flexDirection: 'column'}}>
                  <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Granules ({analyticsGranules * 25})</h5>
                  <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                      <IconButton 
                        onClick={() => {
                          updateAnalyticsFilters(analyticsConfidence, analyticsGranules - 1, analyticsSize)
                        }}>
                        <Grid3x3 color='primary' />
                      </IconButton>
                      <Slider 
                          aria-label="Granules" 
                          value={analyticsGranules} 
                          min={0}
                          max={4}
                          step={1}
                          marks={false}
                          color='secondary'
                          onChange={(event) => {
                              
                            updateAnalyticsFilters(analyticsConfidence, event.target.value, analyticsSize)
                          }} />
                      <IconButton 
                        onClick={() => {
                          updateAnalyticsFilters(analyticsConfidence, analyticsGranules + 1, analyticsSize)
                        }}>
                        <Grid4x4 color='primary' />
                      </IconButton>
                  </Stack>
              </div>       
              <div style={{display: showAnalytics ? 'flex':'none', flexDirection: 'column'}}>
                  <h5 style={{marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row'}}>Size ({scaleValue(analyticsSize.min).toLocaleString()} - {scaleValue(analyticsSize.max).toLocaleString()})</h5>
                  <Stack spacing={2} direction="row" sx={{ alignItems: 'center', }}>
                      <IconButton 
                        onClick={() => {
                          updateAnalyticsFilters(analyticsConfidence, analyticsGranules, { min: analyticsSize.min - 1, max: analyticsSize.max})
                        }}>
                        <FullscreenExit color='primary' />
                      </IconButton>
                      <Slider 
                          aria-label="Size" 
                          value={[analyticsSize.min, analyticsSize.max]} 
                          min={1}
                          max={100}
                          step={1}
                          marks={false}
                          color='secondary'
                          onChange={(event) => {
                            const tempAnalyticsSize = {
                              min: event.target.value[0],
                              max: event.target.value[1]
                            }
                            updateAnalyticsFilters(analyticsConfidence, analyticsGranules, tempAnalyticsSize)
                          }} />
                      <IconButton 
                        onClick={() => {
                          updateAnalyticsFilters(analyticsConfidence, analyticsGranules, {min: analyticsSize.min, max: analyticsSize.max + 1})
                        }}>
                        <Fullscreen color='primary' />
                      </IconButton>
                  </Stack>
              </div>              
            </div>   

          
      </Paper>
      <Paper  
        className='centerOnLotButton'
        elevation={5}
        style={{
          bottom: availableDateMarks?.marks?.length ? '89px':'24px', 
          display: !mapIsStreetViewVisible ? 'flex':'none',
          backgroundColor: selectedLotKey != "" ? `#ffffff`:theme.palette.gray.main
          }}>
        <IconButton
          
          onClick={() => {

            centerOnSelectedLot()

          }}
          disabled={selectedLotKey == ""}
          >
          <ControlCamera color={
            selectedLotKey != "" ? "primary":theme.palette.gray.contrastText
          }/>
        </IconButton>
      </Paper>
      {
        isLoaded ? 
          <GoogleMap 
            zoom={zoom} 
            center={location}        
            mapContainerClassName='mapViewBody'
            mapTypeId={mapTypeId}
            options={{
              styles: googleMapsStyle,
              disableDoubleClickZoom: true,
              fullscreenControl: false,
              zoomControl: false,
              mapTypeControl: false,
            }}
            onDblClick={(e) => {
              selectLot(null)
            }}
            onLoad={mapHandleOnLoad}>
              <StreetViewPanorama
                onLoad={onStreetViewLoad}
                visible={mapIsStreetViewVisible}
                options={{
                  enableCloseButton: false, // Remove the close button
                  addressControl: false,    // Remove the address info box
                  panControl: false,        // Remove the pan control
                  zoomControl: false,       // Remove the zoom control
                  fullscreenControl: false, // Remove the fullscreen control
                  motionTrackingControl: false, // Remove motion tracking control
                }}>

              </StreetViewPanorama>
              
              {
                // organization lot polygons
                Object.entries(organizationLots).map(([key, lot]) => {

                  try{
                    const lotPolygon = lot.polygon
                    const activePolygon = selectedLotKey == key
                    const aPolygonIsSelected = selectedLotKey != ""
                    var zIndex = 101
                    var options = {
                      strokeColor: theme.palette.secondary.main,
                      fillColor: theme.palette.secondary.main,
                      fillOpacity: 0.5,
                      strokeOpacity: 0.8,
                      strokeWeight: 4,
                      zIndex: 1,
                    }

                    if(activePolygon){
                      zIndex = 102
                      options = {
                        strokeColor: theme.palette.secondary.main,
                        fillOpacity: 0,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        zIndex: 102,
                      }
                    }else if(aPolygonIsSelected){
                      zIndex = 1
                      options = {
                        strokeColor: theme.palette.secondary.main,
                        fillOpacity: 0,
                        strokeOpacity: 0.8,
                        strokeWeight: 0,
                        zIndex: 1,
                      }
                    }

                    return <Polygon
                            path={lotPolygon}
                            zIndex={zIndex}
                            options={options}
                            onDblClick={() => {
                              selectLot(key)
                            }}
                          />
                  }catch(error){
                    console.error(error)
                  }
                })
              }
              {
                currentLoadedImages.map((imageData) => {
                  
                  return <GroundOverlay
                            key={`currentSquareVisiblePngs-${imageData?.lotId}-${imageData?.squareId}-${imageData?.dateId}`}
                            url={imageData?.pngImagery?.url}
                            bounds={imageData?.bounds}
                            opacity={currentDateId == imageData?.dateId && showPNGImagery ? (mapPanoramaIsDragging ? .75:1):0}
                            zIndex={102}
                            options={{
                              clickable: false,
                              zIndex: 102,                                  
                            }}
                            onLoad={() => {

                              updateDateSliderMarksLoadState(imageData?.lotId, imageData?.squareId, imageData?.dateId)

                            }}
                          />

                })
              }
              {
                showAnalytics ?
                  currentLoadedAnalyticsFiltered?.["changeGroups"]?.map((changeGroup, index) => {

                    return <Polygon
                              key={`analytics-${changeGroup.lotId}-${index}`}
                              path={changeGroup.p}
                              options={{
                                strokeColor: theme.palette.secondary.main,
                                fillColor: theme.palette.secondary.main,
                                fillOpacity: 0,
                                strokeOpacity: 1,
                                strokeWeight: 3,
                                zIndex: 102,
                              }}
                            />
                  })
                :null
              }
              
          </GoogleMap>
          :
          <div className='mapViewBodyLoading' style={{backgroundColor: theme.palette.primary.halfOpacity, }}>
            <CircularProgress color="secondary" />
          </div>
        
      }
      {
        availableDateMarks?.marks?.length ?? 0 > 0 ?
        (
          <Paper className='mapViewFooter'>        
            <Slider
              step={null}
              value={dateSliderValue}
              onChange={(e) => {
                handleDateSliderChange(e.target.value, true)
              }}
              marks={availableDateMarks?.marks}
              min={availableDateMarks?.min}
              max={availableDateMarks?.max}
              size='small'
              valueLabelDisplay="on"
              valueLabelFormat={(value) => {

                const tempCurrentLotTimeMappedData = timeMappedDataRef.current[selectedLotRef.current?.id]
                const tempAvailableDateMarks = extractAllDatesFromTimeMappedData(tempCurrentLotTimeMappedData)
                const tempDateSliderDisplayDate = tempAvailableDateMarks?.marks?.find(a => a.value == dateSliderValueRef.current)?.dateElement?.displayDate ?? null    
                return dateToLocaleUTCDateString(
                  tempDateSliderDisplayDate, 
                  "en-US", 
                  {year: 'numeric', month: 'long'})                
              }}
              color="secondary"                       
              sx={dateSliderMarksLoadStateStyle}
              onKeyDown={(e) => {
                e.preventDefault();
                
              }}/>          
          </Paper>
        ):null
      }
      
    </div> 
  )
}


export default MapView