

export function calculateDateMarkStyles(_marks, _loadState, _dateString, _theme, _renderLoadStateItemsLarge){

  console.log(_marks, _loadState, _dateString, _theme, _renderLoadStateItemsLarge)

  const returnObj = {
    '& .MuiSlider-mark': {
      height: '6px',
      width: '6px',
      marginLeft: '-1px',
      borderWidth: '2px',
    },      
  }
  try{
  
    _marks.forEach((mark, index) => {      
      returnObj[`& .MuiSlider-mark[data-index="${index}"]`] = {}
      Object.values(_loadState ?? {}).forEach((squareData) => {

        if(squareData[mark.dateElement.endDateId] == true){     
          returnObj[`& .MuiSlider-mark[data-index="${index}"]`]['backgroundColor'] = _theme.palette.secondary.main
        
          if(!_renderLoadStateItemsLarge){
            returnObj[`& .MuiSlider-mark[data-index="${index}"]`]['height'] = '3px'
            returnObj[`& .MuiSlider-mark[data-index="${index}"]`]['width'] = '3px'
            returnObj[`& .MuiSlider-mark[data-index="${index}"]`]['borderWidth'] = '1px'
          }
        } 
      })
      if(mark.label != ''){
        returnObj[`& .MuiSlider-mark[data-index="${index}"]`]['borderWidth'] = '3px'
      }
    })

    const analyticDateTime = (new Date(Date.UTC(parseInt(_dateString.split('-')?.[0]), parseInt(_dateString.split('-')?.[1]) - 1, parseInt(_dateString.split('-')?.[2])))).getTime()      
    const selectedMark = _marks?.find(mark => mark.value == analyticDateTime)

    if(selectedMark != null){
      //get the index of the selected mark
      const selectedIndex = _marks.indexOf(selectedMark)
      returnObj[`& .MuiSlider-mark[data-index="${selectedIndex}"]`] = {
        backgroundColor: _theme.palette.secondary.main,
        borderColor: _theme.palette.secondary.main,
        height: '10px',
        width: '10px',
        marginLeft: '-3px',

      }    
    }

  }catch(error){
    console.error("Error calculating date mark styles: " + error)
  }

  return returnObj

}