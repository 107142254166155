

export function calculateRelativeDateSliderMarkValue(_marks, _analyticKey, _relativeValueMonths){
    try{

      var analyticDateTime 
      if(_analyticKey == null){
        analyticDateTime = _marks[_marks.length - 1].value
      }else{
        analyticDateTime= (new Date(Date.UTC(parseInt(_analyticKey.split('-')?.[0]), parseInt(_analyticKey.split('-')?.[1]) - 1, parseInt(_analyticKey.split('-')?.[2])))).getTime()      
      }

      const selectedMark = _marks?.find(mark => mark.value == analyticDateTime)

      if(selectedMark == null){
        return _marks[_marks.length - 1].value
      }
    
      const currentIndex = _marks.indexOf(selectedMark)

      //take the value of the current index and add the _relativeValueMonths to it
      //create a new date with that value
      
      var relativeDate = new Date(selectedMark.value)
      relativeDate.setMonth(relativeDate.getMonth() + _relativeValueMonths)
      const relativeDateUTC = relativeDate.getTime()
      //find the mark that is closest to the new date
      var closestMark = _marks.reduce((prev, curr) => Math.abs(curr.value - relativeDateUTC) < Math.abs(prev.value - relativeDateUTC) ? curr : prev)
 
      return closestMark.value


    }catch(error){
      console.error("Error calculating relative date slider mark value: " + error)
      return 0
    }
  }